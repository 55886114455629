// Libraries
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Row, Col, Divider, Select, Modal, Cascader } from 'antd';
import { LogoutOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';

// Custom
import { useDispatch } from 'hooks/useCustomDispatch';

// Components
import ImageField from 'components/Desktop/Commons/ImageField';
import SalonAreaSelectForm from 'components/Desktop/Salon/SalonAreaSelectForm';
import SalonEditPlaceOfThePin from './SalonEditPlaceOfThePin';

// Providers
import { getAddressByPostCode } from 'providers/AuthProvider/actions';
import { salonAccountUpdate, salonGetProperties } from 'providers/SalonProvider/actions';

// Utils
import { getLanguages } from 'utils/lang';
import pinMarker from 'assets/images/pin-marker.png';
import './styles.less';
import searchSalonLocation from 'utils/googleMapAPI';
import { getLineList, getPrefectureISOTable, getStationList, resetStationProvider } from 'providers/StationProvider/action';
import BigSpin from '../Commons/BigSpin';
import { SALON_HIDDEN_TYPES } from 'utils/SharedSalonEnv';
import ChangeEmailModal from '../Modal/ChangeEmailModal';

const { TextArea } = Input;
const { Option } = Select;

const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const hideFullScreenOptions = function (maps) {
  return {
    fullscreenControl: false,
    zoomControl: false,
  };
};

const SalonAccountUpdateForm = ({ salon, onCallback, isCompleteProfile = false }) => {
  // languages
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { logout } = useAuth0();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // Constants
  const latitude = _.get(salon, 'location.latLng.lat') || 35.652832;
  const longitude = _.get(salon, 'location.latLng.lng') || 139.839478;
  const station = _.get(salon, 'location.station');
  const stationInitValue = station?.lineId ? [`${station?.lineId}|${station?.lineName}`, `${station?.stationId}|${station?.stationName}`] : [];
  const isHiddenTypes = SALON_HIDDEN_TYPES.includes(salon?.salonType);
  // State
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [propertiesSelected, setPropertiesSelected] = useState(_.get(salon, 'properties', []).map(item => item.id));
  const [isSalonAreaSelectModal, setIsSalonAreaSelectModal] = useState(false);
  const [lngValue, setLngValue] = useState({ lat: latitude, lng: longitude });
  const [openEditPlaceOfThePin, setOpenEditPlaceOfThePin] = useState(false);
  const [defaultLng, setDefaultLng] = useState({ lat: latitude, lng: longitude });
  const { prefectureISOTable, lineList } = useSelector((state) => state.station);
  const [isSelectedStation, setSelectedStation] = useState(stationInitValue?.length);
  const [isUnpublicAddress, setUnpublicAddress] = useState(salon?.location?.isPrivate);
  const _refFieldPostCode = useRef(null);
  const [isChangeEmailModal, setIsChangeEmailModal] = useState(false);
  const [currentPostCode, setCurrentPostCode] = useState(_.get(salon, 'location.postCode'));
  // Get prefecture ISO table and the line list first load page
  useEffect(() => {
    (async () => {
      const prefTableTmp = await dispatch(getPrefectureISOTable());
      await getLineListOptions(prefTableTmp);
      const prefectureCodes = prefTableTmp[form.getFieldValue('prefecture')]?.iso;
      const lineValue = `${station?.lineId}|${station?.lineName}`;
      await dispatch(getStationList({ prefectureCodes, lineValue }));
    })();
  }, []);

  const getLineListOptions = (prefTable = prefectureISOTable) => {
    const prefecture = prefTable[form.getFieldValue('prefecture')];
    const prefectureCodes = prefecture?.iso;
    return dispatch(getLineList({ prefectureCodes }));
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const photosLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const renderChangeEmail = () => {
    return (
      <div style={{ marginTop: 12 }}> メールアドレスの変更は
        <a
          onClick={() => setIsChangeEmailModal(true)}
          style={{ textDecoration: 'underline', textUnderlinePosition: 'under' }}>
          こちら
        </a>から</div>
    );
  };

  const onChangeLineOption = (value, selectedOptions) => {
    if (value.length === 2) {
      setSelectedStation(true);
    } else {
      // behavior is the same Nailie App: Clear those field when select new or clear station
      form.setFieldsValue({ walkingTime: '', navigation: '', station: '' });
      setSelectedStation(false);
    }
  };
  // Just show the last item station Name
  const displayStationName = (label) => {
    return label[label.length - 1];
  };

  const loadData = async (selectedOptions) => {
    const prefecture = prefectureISOTable[form.getFieldValue('prefecture')];
    const prefectureCodes = prefecture?.iso;
    const lineValue = selectedOptions[0]?.value;
    const target = _.keyBy(lineList, 'value');
    const targetOption = target[lineValue];
    targetOption.loading = true;
    await dispatch(getStationList({ prefectureCodes, lineValue }));
  };

  useEffect(() => {
    form.validateFields();
    dispatch(salonGetProperties())
      .then(result => {
        setProperties(result.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleLogout = () => {
    Modal.confirm({
      title: lang.confirm,
      icon: <ExclamationCircleOutlined />,
      content: lang.confirmLogout,
      okText: lang.sure,
      cancelText: lang.no,
      onOk: () => {
        localStorage.clear();
        logout({ returnTo: window.location.origin });
      },
    });
  };

  const categorizeProperties = (properties) => {
    return properties.reduce((acc, property) => {
      if (property.serviceRole === 'NAILIST') {
        acc.nailist.push(property);
      } else if (property.serviceRole === 'EYELIST') {
        acc.eyelist.push(property);
      } else {
        acc.common.push(property);
      }
      return acc;
    }, { common: [], nailist: [], eyelist: [] });
  };

  const renderPropertyIds = (properties, propertiesSelected, setPropertiesSelected, form) => {
    const categorizedProperties = categorizeProperties(properties);

    const renderPropertyGroup = (groupProperties, titleKey) => (
      <div style={{ marginBottom: 24 }}>
        <h3 style={{ fontWeight: 'bold', marginBottom: 20 }}>{lang[titleKey]}</h3>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {groupProperties.map(item => {
            let styleSelected;
            const style = {
              height: 40,
              width: 40,
              borderRadius: '50%',
              borderStyle: 'solid',
            };

            if (propertiesSelected.includes(item.id)) {
              styleSelected = { filter: 'invert(30%) sepia(73%) saturate(2251%) hue-rotate(160deg) brightness(94%) contrast(104%)' };
            }

            return (
              <div key={item.id} style={{
                width: '13%',
                textAlign: 'center',
                marginBottom: 24,
                cursor: 'pointer',
              }} onClick={() => {
                if (propertiesSelected.includes(item.id)) {
                  form.setFields([
                    {
                      name: 'propertyIds',
                      value: form.getFieldsValue().propertyIds.filter(x => x !== item.id),
                    },
                  ]);
                  setPropertiesSelected(propertiesSelected.filter(element => element !== item.id));
                } else {
                  form.setFields([
                    {
                      name: 'propertyIds',
                      value: [...form.getFieldsValue().propertyIds, item.id],
                    },
                  ]);
                  setPropertiesSelected([...propertiesSelected, item.id]);
                }
              }}>
                <img
                  style={{ ...style, ...styleSelected }}
                  src={item.icon}
                />
                <div style={{ whiteSpace: 'nowrap', marginTop: 8, ...styleSelected }} >{item.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );

    return (
      <div>
        {renderPropertyGroup(categorizedProperties.common, 'salonCommon')}
        {renderPropertyGroup(categorizedProperties.nailist, 'nailistOnly')}
        {renderPropertyGroup(categorizedProperties.eyelist, 'eyelistOnly')}
      </div>
    );
  };

  const getSalonLocation = () => {
    const salonAddress = '日本 〒' + form.getFieldValue('postCode') + form.getFieldValue('prefecture') + form.getFieldValue('cityOrTown') + form.getFieldValue('address') + form.getFieldValue('building');
    searchSalonLocation(salonAddress)
      .then(result => {
        setLngValue({ lat: result?.lat, lng: result?.lng });
        setDefaultLng({ lat: result?.lat, lng: result?.lng });
      });
  };

  const onSubmitSalonAccountValues = async (values) => {
    setLoading(true);
    try {
      const [lineId, lineName] = values?.station[0]?.split('|') || [];
      const [stationId, stationName] = values?.station[1]?.split('|') || [];
      const result = await dispatch(salonAccountUpdate({
        ...values,
        salonId: _.get(salon, 'id'),
        phoneNumber: values.phone,
        latLng: isCompleteProfile ? values.coordinates : lngValue,
        area: {
          id: values.regionId,
          name: values.regionName,
          prefecture: {
            id: values.prefectureId,
            name: values.prefectureName,
            city: {
              id: values.areaId,
              name: values.areaName,
            },
          },
        },
        station: {
          stationId,
          stationName,
          lineId,
          lineName,
          description: values?.navigation ? values?.navigation.trim() : '',
          walkingTime: values?.walkingTime,
        },
        isPrivate: values.isPrivate,
        logoUrl: values.logoUrl || '',
        photos: isCompleteProfile === false ? values.photos.map(item => item.objectId) : salon.photos,
        propertyIds: isCompleteProfile === false ? propertiesSelected : salon.properties.map(item => item.id),
        description: values?.description ? values?.description.trim() : '',
      }));
      setDefaultLng({ ...lngValue });
      onCallback(result);
    } catch (err) {

    }

    setLoading(false);
  };

  return (
    <Form
      {...layout}
      form={form}
      name="completeForm"
      onFinish={(values) => {
        form.validateFields();
        if (isCompleteProfile) {
          setOpenEditPlaceOfThePin(true);
        } else {
          onSubmitSalonAccountValues(values);
        }
      }
      }
      initialValues={{
        businessName: _.get(salon, 'businessName'),
        logoUrl: salon?.logoUrl || '',
        email: _.get(salon, 'operatorEmail'),
        phone: _.get(salon, 'operatorPhoneNumber'),

        regionId: _.get(salon, 'location.area.id'),
        regionName: _.get(salon, 'location.area.name'),
        prefectureId: _.get(salon, 'location.area.prefecture.id'),
        prefectureName: _.get(salon, 'location.area.prefecture.name'),
        areaId: _.get(salon, 'location.area.prefecture.city.id'),
        areaName: _.get(salon, 'location.area.prefecture.city.name'),

        station: stationInitValue,
        walkingTime: _.get(salon, 'location.station.walkingTime'),
        navigation: _.get(salon, 'location.station.description'),

        postCode: _.get(salon, 'location.postCode'),
        prefecture: _.get(salon, 'location.prefecture'),
        cityOrTown: _.get(salon, 'location.cityOrTown'),
        address: _.get(salon, 'location.address'),
        building: _.get(salon, 'location.building'),
        isPrivate: _.get(salon, 'location.isPrivate'),
        propertyIds: _.get(salon, 'properties', []).map(item => item.id),
        description: _.get(salon, 'description'),
        photos: _.get(salon, 'photos', []).map(item => {
          return {
            objectId: item,
            thumbSmall: item,
          };
        }),
      }}
    >
      {isCompleteProfile === false &&
        <Row gutter={24} type="flex">
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {lang.updateAndPublish}
            </Button>
          </Col>
        </Row>
      }
      <Form.Item
        name="logoUrl"
        label={lang.logo}
        extra={lang.salonLogoExtraUpload}
      >
        <ImageField />
      </Form.Item>
      <Form.Item
        name="businessName"
        label={lang.businessName}
        validateTrigger={'onBlur'}
        onKeyPress={(e) => {
          if (e.target.value.length >= 50) {
            e.preventDefault();
          }
        }}
        rules={[
          {
            required: true,
            message: lang.emptyBusinessNameError,
          },
          {
            min: 2,
            message: lang.minLength1Error,
          },
          {
            max: 50,
            message: lang.maxLength50Error,
          },
          {
            whitespace: true,
            message: lang.emptyBusinessNameError,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label={lang.email}
        extra={renderChangeEmail()}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="phone"
        label={lang.phone}
        validateTrigger={'onBlur'}
        rules={[
          {
            required: true,
            message: lang.emptyPhoneNumberError,
          },
          {
            pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
            message: lang.invalidPhoneNumberError,
          },
          {
            min: 10,
            message: lang.minLengthPhoneNumberError,
          },
          {
            max: 11,
            message: lang.minLengthPhoneNumberError,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="regionId" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item name="regionName" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item name="prefectureId" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item name="prefectureName" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item name="areaId" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item name="areaName" style={{ display: 'none' }}>
        <Input />
      </Form.Item>

      <Form.Item
        name="postCode"
        label={lang.postCode}
        validateTrigger={'onBlur'}
        rules={[
          {
            required: true,
            message: lang.emptyPostCodeError,
          },
          {
            len: 7,
            message: lang.mustInput7Character,
          },
        ]}
      >
        <Input
          ref={_refFieldPostCode}
          onKeyPress={(e) => {
            const key = e.which || e.key;
            if (e.target.value.length < 7) {
              if (key < 48 || key > 57) { e.preventDefault(); }
            } else e.preventDefault();
          }}
          onChange={(e) => {
            if (e.target.value.length === 7) {
              _refFieldPostCode.current.blur();
            }
          }}
          onBlur={(e) => {
            // observe postCode value  and prevent fetch data if it doesn't change
            setCurrentPostCode(e.target.value);
            if (e.target.value === currentPostCode) {
              return;
            }
            if (e.target.value !== '' && e.target.value !== undefined && e.target.value.length === 7) {
              dispatch(getAddressByPostCode({ postCode: e.target.value }))
                .then(result => {
                  if (result.data.cityOrTown) {
                    form.setFieldsValue({ cityOrTown: result.data.cityOrTown });
                  }

                  if (result.data.prefecture) {
                    form.setFieldsValue(
                      {
                        prefecture: result.data.prefecture,
                        address: '',
                        building: '',
                        walkingTime: '',
                        navigation: '',
                        station: '',
                        areaId: '',
                        areaName: '',
                      });
                    setSelectedStation(false);
                    getLineListOptions(); // Get new line list with new prefecture
                  }
                })
                .catch(error => {
                  form.setFieldsValue({ cityOrTown: '' });
                  form.setFieldsValue({ prefecture: '' });
                  if (error.data.length > 0) {
                    let msg = '';
                    error.data.forEach((item) => {
                      msg = `${msg} ${item.message}. `;
                    });
                    form.setFields([
                      {
                        name: 'postCode',
                        errors: [msg],
                      },
                    ]);
                  } else {
                    form.setFields([
                      {
                        name: 'postCode',
                        errors: [error.message],
                      },
                    ]);
                  }
                });
              // Render pin map base on postCode and set default Lat, Lng when user reset map
              getSalonLocation();
            } else if (e.target.value === '') {
              form.setFieldsValue(
                {
                  prefecture: '',
                  cityOrTown: '',
                  address: '',
                  building: '',
                  walkingTime: '',
                  navigation: '',
                  station: '',
                  areaId: '',
                  areaName: '',
                });
              setSelectedStation(false);
              dispatch(resetStationProvider()); // clear select line/station list when not input postcode
            }
          }} />
      </Form.Item>

      <Form.Item
        name="prefecture"
        label={lang.prefectures}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="cityOrTown"
        label={lang.cityOrTown}
        rules={[
          {
            required: true,
            message: lang.emptyAddressTownError,
          },
          {
            whitespace: true,
            message: lang.emptyAddressTownError,
          },
        ]}
        onBlur={() => getSalonLocation()}
      >
        <Input maxLength={50}/>
      </Form.Item>

      <Form.Item
        name="address"
        label={lang.address}
        rules={[
          { required: true, message: lang.emptyAddressError },
          {
            whitespace: true,
            message: lang.emptyAddressError,
          }]}
        onBlur={() => getSalonLocation()}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="building"
        label={`${lang.buildingName}/ ${lang.roomNumber}`}
        onBlur={() => getSalonLocation()}
      >
        <Input maxLength={50}/>
      </Form.Item>
      {isCompleteProfile === false &&
        <Form.Item
          label={lang.mapSettings}
          extra={<div style={{ marginTop: 6 }}>{lang.mapSettingsExtra}</div>}
        >
          <a onClick={() => setOpenEditPlaceOfThePin(true)} >
            <div style={{ height: '35vh', width: '100%', position: 'relative', pointerEvents: 'none' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapKey }}
                defaultCenter={defaultLng}
                center={lngValue}
                defaultZoom={16}
                options={hideFullScreenOptions}
              />
              <img src={pinMarker}
                style={{
                  height: 40,
                  position: 'absolute',
                  bottom: '50%',
                  left: '50%',
                  transform: 'translate(-50%, 0px)',
                }} />
            </div>
          </a>

        </Form.Item>
      }

      {/* Private / public address */}
      <Form.Item
        name="isPrivate"
        label={lang.addressMode}
        rules={[
          {
            required: true,
            message: lang.emptyAddressModeError,
          },
        ]}
        extra={lang.addressModeDescription}
      >
        <Select
          onChange={(value) => {
            setUnpublicAddress(value);
          }}
          style={{ width: '100%' }}
          placeholder={lang.addressMode}
        >
          <Select.Option value={false}>{lang.addressModePublic}</Select.Option>
          <Select.Option value={true}>{lang.addressModePrivate}</Select.Option>
        </Select>
      </Form.Item>

      {/* Salon area */}
      <Form.Item
        label={lang.salonArea}
        name="areaName"
        rules={[{ required: true, message: lang.emptySalonAreaError }]}
      >
        <div >
          <a onClick={() => setIsSalonAreaSelectModal(true)}>
            {form.getFieldsValue().areaName
              ? form.getFieldsValue().areaName
              : lang.select
            }
          </a>
        </div>
      </Form.Item>

      {/* Salon Station */}
      <div id="salon_station">
        <Form.Item
          label={lang.nearestStation}
          validateTrigger={'onChange'}
          name="station"
          extra={<div style={{ marginTop: 16 }}>{lang.nearestStationExtraInfo1}<br />{lang.nearestStationExtraInfo2}</div>}
          rules={[
            {
              required: isHiddenTypes,
              message: '最寄駅を入力してください',
            },
          ]}
        >
          <Cascader
            options={lineList}
            onChange={onChangeLineOption}
            loadData={loadData}
            changeOnSelect
            displayRender={displayStationName}
            placeholder={lang.select}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </div>

      {isCompleteProfile === false &&
        <>
          {/* Do not allow to select the Walking Time and the memo field if Nailist doesn't select a Station. */}
          <div>
            <Form.Item
              name="walkingTime"
              label={lang.walkingTime}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder={lang.walkingTime}
                disabled={!isSelectedStation}
              >
                {[...Array(61).keys()].map(m => <Option key={m} value={m}>{m} {lang.minutesWakingTime}</Option>)}
              </Select>
            </Form.Item>
            {/* // Do not show this section if Nailist select “Unpublic” */}
            {!isUnpublicAddress &&
              <Form.Item
                name="navigation"
                label={lang.navigationAccess}
              >
                <TextArea
                  placeholder={lang.navigationAccess}
                  rows={3}
                  showCount
                  disabled={!isSelectedStation}
                  maxLength={500} />
              </Form.Item>
            }
          </div>
          <h3 id="salon_properties">{lang.salonProperties}</h3>
          <Divider style={{ marginTop: 15 }} />
          <Form.Item
            name="propertyIds"
            wrapperCol={{ xs: { span: 24, offset: 0 }, lg: { span: 20, offset: 2 }, xl: { span: 16, offset: 4 } }}
          >
            {renderPropertyIds(properties, propertiesSelected, setPropertiesSelected, form)}
          </Form.Item>
          <h3>{lang.salonDescription}</h3>
          <Divider style={{ marginTop: 15 }} />
          <Form.Item
            name="description"
            wrapperCol={{ xs: { span: 24, offset: 0 }, lg: { span: 20, offset: 2 }, xl: { span: 16, offset: 4 } }}
            colon={false}
            extra={<div style={{ marginTop: 16 }}>{lang.salonDescriptionExtra}</div>}
          >
            <TextArea
              placeholder={lang.salonDescriptionPlaceholder}
              rows={5}
              showCount
              maxLength={10000} />
          </Form.Item>
          <h3>{lang.salonImages}</h3>
          <Divider style={{ marginTop: 15 }} />
          <Form.Item
            {...photosLayout}
            name="photos"
            label={' '}
            colon={false}
            extra={lang.salonImagesExtraUpload}
            rules={[]}
          >
            <ImageField maxLength={3} aspect={5 / 4} />
          </Form.Item>
        </>
      }
      {isCompleteProfile === false ? (
        <>
          <Divider />
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                {lang.updateAndPublish}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              danger
              icon={<LogoutOutlined />}
              onClick={handleLogout}>
              {lang.logout}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {lang.continue}
            </Button>
          </div>
        </>
      )}

      <Modal
        visible={isSalonAreaSelectModal}
        title={lang.selectSalonArea}
        onCancel={() => setIsSalonAreaSelectModal(false)}
        destroyOnClose={true}
        width={600}
        centered
        footer={null}
        maskClosable={false}
      >
        <SalonAreaSelectForm
          prefecture={form.getFieldValue('prefecture')}
          salonArea={{
            regionId: _.get(salon, 'location.area.id'),
            regionName: _.get(salon, 'location.area.name'),
            prefectureId: _.get(salon, 'location.area.prefecture.id'),
            prefectureName: _.get(salon, 'location.area.prefecture.name'),
            areaId: form.getFieldValue('areaId'),
            areaName: form.getFieldValue('areaName'),
          }}
          onCancel={() => setIsSalonAreaSelectModal(false)}
          onCallback={salonArea => {
            const { regionId, regionName, prefectureId, prefectureName, areaId, areaName } = salonArea;

            form.setFieldsValue({ regionId });
            form.setFieldsValue({ regionName });
            form.setFieldsValue({ prefectureId });
            form.setFieldsValue({ prefectureName });
            form.setFieldsValue({ areaId });
            form.setFieldsValue({ areaName });

            setIsSalonAreaSelectModal(false);
          }}
        />
      </Modal>
      <Modal
        visible={openEditPlaceOfThePin}
        title={lang.editPlaceOfPin}
        onCancel={() => setOpenEditPlaceOfThePin(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        maskClosable={false}
      >
        {loading && <BigSpin />}
        <SalonEditPlaceOfThePin
          salon={salon}
          coordinateInitValue={lngValue}
          isCompleteProfile={isCompleteProfile}
          onCancel={() => setOpenEditPlaceOfThePin(false)}
          handleSetCoordination={(coordinatesData) => {
            const { coordinates } = coordinatesData;
            setLngValue({ lat: coordinates?.lat, lng: coordinates?.lng });
            // Select place of the pin and complete update profile
            if (isCompleteProfile) {
              const values = { ...form.getFieldsValue(), coordinates };
              onSubmitSalonAccountValues(values);
              return;
            }
            setOpenEditPlaceOfThePin(false);
          }}
        />
      </Modal>
      <ChangeEmailModal
        salon={salon}
        isChangeEmailModal={isChangeEmailModal}
        setIsChangeEmailModal= {setIsChangeEmailModal}
        onCallback={onCallback}
      />
    </Form>
  );
};

SalonAccountUpdateForm.propTypes = {
  onCallback: PropTypes.func,
  salon: PropTypes.object,
  isCompleteProfile: PropTypes.bool,
};

export default SalonAccountUpdateForm;
