export const getLanguages = t => {
  return {
    // Breadcrumb
    accountSettings: t('Account settings'),
    bookings: t('Bookings'),
    changePassword: t('Change Password'),
    customerDetail: t('Customer Detail'),
    customers: t('Customers'),
    home: t('Home'),
    logout: t('Logout'),
    mySalon: t('My Salon'),
    payment: t('Payment'),
    report: t('Report'),
    manageBookings: t('Manage bookings'),
    menuSettings: t('MenuSettings'),
    services: t('Services'),
    salesDetail: t('Sales detail'),
    salesManagement: t('Sales management'),
    salonAccount: t('Salon Account'),
    searchBookings: t('Search bookings'),
    settings: t('Settings'),
    staff: t('Staff'),
    staffMember: t('Staff Member'),
    staffOverview: t('Staff Overview'),
    staffProfile: t('Staff Profile'),
    staffSchedule: t('Staff Schedule'),
    salonSaleReport: t('Salon sales report'),
    staffSaleReport: t('Staff sales report'),
    transferManagement: t('Transfer management'),

    // Action
    action: t('Action'),
    cancel: t('Cancel'),
    change: t('Change'),
    changePrice: t('ChangePrice'),
    editPrice: t('EditPrice'),
    changeDateTime: t('Change date time'),
    complete: t('Complete'),
    copy: t('Copy'),
    edit: t('Edit'),
    export: t('Export'),
    exportCSV: t('ExportCSV'),
    delete: t('Delete'),
    details: t('Details'),
    disconnect: t('Disconnect'),
    import: t('Import'),
    resend: t('Resend'),
    save: t('Save'),
    select: t('Select'),
    selectedOption: t('SelectedOption'),
    editPlaceOfPin: t('EditPlaceOfPin'),
    mapSettings: t('MapSettings'),
    mapSettingsExtra: t('MapSettingsExtra'),
    search: t('Search'),
    editAccount: t('Edit Account'),
    editAccountInfo: t('Edit Account Info'),
    editBanking: t('Edit Banking'),
    editBankInfo: t('Edit Bank Info'),
    updateBankAccountInfo: t('Update Bank Account Info'),
    createBooking: t('Create Booking'),
    updateBooking: t('Update Booking'),
    cancelBooking: t('Cancel Booking'),
    unableCompleteTreatment: t('UnableCompleteTreatment'),
    addCategory: t('Add Category'),
    updateCategory: t('Update Category'),
    addCustomer: t('Add Customer'),
    newCustomer: t('New Customer'),
    editCustomer: t('Edit Customer'),
    updateCustomer: t('Update Customer'),
    addDiscount: t('Add Discount'),
    addJapanPublicHolidays: t('Add Japan public holidays'),
    addNewClosedDate: t('Add new closed date'),
    uploadPhoto: t('Upload Photo'),
    editProfile: t('Edit Profile'),
    removeStaff: t('RemoveStaff'),
    add: t('Add'),
    addSchedule: t('Add Schedule'),
    editSchedule: t('Edit Schedule'),
    addMenu: t('Add Menu'),
    updateService: t('Update Service'),
    updateMenu: t('Update Menu'),
    addStaff: t('Add Staff'),
    addTips: t('Add Tips'),
    removeTips: t('Remove tips'),
    editWorkingTime: t('EditWorkingTime'),
    updateWorkingTime: t('UpdateWorkingTime'),
    quickPayment: t('Quick Payment'),
    processPayment: t('Process payment'),
    updateAndPublish: t('Update and Publish'),
    continue: t('Continue'),
    importFromNailie: t('Import from Nailie'),
    sentInvitation: t('Sent invitation'),
    okText: t('OkText'),
    cancelText: t('CancelText'),
    submitStaffWorkingTime: t('Save and apply for Staff'),
    disable: t('Disable'),
    enable: t('Enable'),
    send: t('Send'),
    viewHere: t('ViewHere'),
    showBooking: t('Show Booking'),
    setRecommend: t('SetRecommend'),
    removeRecommend: t('RemoveRecommend'),
    viewMoreDetail: t('View more detail'),
    reload: t('Reload'),
    reset: t('Reset'),

    saveAndPublishSettings: t('SaveAndPublishSettings'),
    // Title
    account: t('Account'),
    bankingAccount: t('Banking Account'),
    bankingInfomation: t('Banking Information'),
    billing: t('Billing'),
    bookingData: t('Booking data'),
    bookingDetail: t('Booking Detail'),
    changePasswordProcess: t('Change password process'),
    cashPayment: t('Cash payment'),
    closeDates: t('Close dates'),
    completeBooking: t('Complete booking'),
    completeBusinessInfo: t('CompleteBusinessInfo'),
    settingBusinessHours: t('SettingBusinessHours'),
    connected: t('Connected'),
    customerInfo: t('Customer Info'),
    earnings: t('Earnings'),
    notes: t('Notes'),
    paymentDetail: t('Payment Detail'),
    paymentInfo: t('Payment Info'),
    paymentMethod: t('Payment method'),
    personalInfo: t('Personal Info'),
    profile: t('Profile'),
    salary: t('Salary'),
    sales: t('Sales'),
    sale: t('Sale'),
    salon: t('Salon'),
    schedule: t('Schedule'),
    cancellationPolicy: t('CancellationPolicy'),
    cancellationPolicyNote1: t('CancellationPolicyNote1'),
    cancellationPolicyNote2: t('CancellationPolicyNote2'),
    continueToCompleteYourSalonSchedule: t('Continue to complete your Salon schedule'),
    continueToCompleteYourSalonSettingsToStartYourBusiness: t('Continue to complete your Salon settings to start your business'),
    searchResult: t('Search Result'),
    timesheet: t('Timesheet'),
    tips: t('Tips'),
    rating: t('Rating'),
    workingTime: t('WorkingTime'),
    other: t('Other'),
    surveyCoronaText: t('SurveyCoronaText'),
    acceptanceTimeTitle: t('AcceptanceTimeTitle'),
    cancellationChargeTitle: t('CancellationChargeTitle'),
    cancellationChargeContentHead: t('CancellationChargeContentHead'),
    cancellationChargeContentMiddle: t('CancellationChargeContentMiddle'),
    cancellationChargeContentTail: t('CancellationChargeContentTail'),
    cancellationChargeContentNofee: t('CancellationChargeContentNofee'),
    nailistSalesPrice: t('NailistSalesPrice'),
    paymentSummary: t('PaymentSummary'),
    salonBMSchedule: t('SalonIntegrationTitle'),
    salonConnect3rdPartiesScheduleDescription: t('SalonConnect3rdPartiesScheduleDescription'),
    // Label
    salonBMScheduleLabel: t('SalonBMScheduleLabel'),
    accountName: t('Account name'),
    accountNumber: t('Account number'),
    accountType: t('Account type'),
    addDate: t('Add date'),
    additionalPrice: t('Additional price'),
    address: t('Address'),
    allStaff: t('All staff'),
    average: t('Average'),
    bankBranch: t('Bank branch'),
    bankName: t('Bank name'),
    bankbookTitle: t('BankbookTitle'),
    bankbookDescription1: t('BankbookDescription1'),
    bankbookDescription2: t('BankbookDescription2'),
    billingCount: t('Billing count'),
    birthday: t('Birthday'),
    bookingDate: t('Booking Date'),
    bookingTime: t('BookingTime'),
    bookingDone: t('Booking Done'),
    bookingID: t('Booking ID'),
    branchName: t('Branch name'),
    addressMode: t('Address mode'),
    addressModePublic: t('Always show salon address'),
    addressModePrivate: t('Show salon address only to the confirmed customer'),
    addressModeDescription: t('Select an option that you want to public salon address or not'),
    salonArea: t('Salon area'),
    selectSalonArea: t('Select Salon Area'),
    region: t('Region'),
    prefecture: t('Prefecture'),
    area: t('Area'),
    buildingName: t('Building name'),
    businessHour: t('Business Hour'),
    businessName: t('Business name'),
    businessType: t('Business Type'),
    canceledAmounts: t('Canceled amounts'),
    cancellationFee: t('Cancellation fee'),
    categoryDescription: t('Category Description'),
    categoryName: t('Category Name'),
    changeDateReasonLabel: t('ChangeDateReasonLabel'),
    cityOrTown: t('City or Town'),
    createDate: t('Create Date'),
    createdDate: t('Created date'),
    customer: t('Customer'),
    customerCanceled: t('Customer canceled'),
    customerID: t('Customer ID'),
    customerName: t('Customer name'),
    customerPay: t('Customer pay'),
    rankBronze: t('RankingBronze'),
    rankSilver: t('RankingSilver'),
    rankGold: t('RankingGold'),
    rankPlatinum: t('RankingPlatinum'),
    rankDiamond: t('RankingDiamond'),
    estimate: t('Estimate'),
    date: t('Date'),
    description: t('Description'),
    discount: t('UseCoupon'),
    usePoint: t('UsePoint'),
    discountCash: t('Discount Cash'),
    doneAmounts: t('Done amounts'),
    duration: t('Duration'),
    email: t('Email'),
    emailAddress: t('Email address'),
    emailSettingDescription: t('EmailSettingDescription'),
    labelName: t('Label name'),
    defaultLabel: t('Default Label'),
    endTime: t('EndTime'),
    gender: t('Gender'),
    subtotal: t('Subtotal'),
    lastVisitDate: t('Last Visit Date'),
    logo: t('Logo'),
    memo: t('Memo'),
    menuDetail: t('Menu Detail'),
    menuNewText: t('MenuNewText'),
    menuRepeaterText: t('MenuRepeaterText'),
    menuAllUserText: t('MenuAllUserText'),
    menuNewEditText: t('MenuNewEditText'),
    menuRepeaterEditText: t('MenuRepeaterEditText'),
    menuAllUserEditText: t('MenuAllUserEditText'),
    serviceRoleTitle: t('ServiceRoleTitle'),
    menuTitle: t('MenuTitle'),
    detailBtn: t('DetailBtn'),
    menuStatusAssigned: t('MenuStatusAssigned'),
    menuStatusNotAssigned: t('MenuStatusNotAssigned'),
    bookingAvailabilityOK: t('BookingAvailabilityOK'),
    bookingAvailabilityNO: t('BookingAvailabilityNO'),
    bookingAvailabilityTitle: t('BookingAvailabilityTitle'),
    salonManager: t('Salon Manager'),
    modalInforTitle: t('ModalInforTitle'),
    modalInforContent: t('ModalInforContent'),
    menuImage: t('Menu Image'),
    fee: t('Fee'),
    countDoneNew: t('CountDoneNew'),
    countDoneRepeater: t('CountDoneRepeater'),
    bookingCustomerTypeTitle: t('BookingCustomerTypeTitle'),
    newType: t('NewType'),
    repeatType: t('RepeatType'),
    nailieFee: t('Nailie fee'),
    nailieUsername: t('Nailie username'),
    userName: t('UserName'),
    name: t('Name'),
    fullName: t('FullName'),
    newBookingDate: t('New booking date'),
    note: t('Note'),
    onlinePayment: t('Online payment'),
    paymentID: t('Payment ID'),
    paymentReport: t('Payment report'),
    avatar: t('Avatar'),
    phonetic: t('Phonetic'),
    period: t('Period'),
    personalPhoneNumber: t('PersonalPhoneNumber'),
    phone: t('Phone'),
    postCode: t('Post Code'),
    prefectures: t('Prefectures'),
    price: t('Price'),
    includedTax: t('IncludedTax'),
    priceIncludedTax: t('PriceIncludedTax'),
    ranking: t('Ranking'),
    refundReason: t('Refund reason'),
    reason: t('Reason'),
    roomNumber: t('room number'),
    salesAmount: t('Sales amount'),
    salonCanceled: t('Salon canceled'),
    selectYear: t('Select year'),
    menuType: t('Menu Type'),
    menuCategory: t('Menu Category'),
    menuName: t('Menu Name'),
    staffName: t('Staff name'),
    startTime: t('Start Time'),
    status: t('Status'),
    statusUpdate: t('Status update'),
    system: t('System'),
    targetSystem: t('Target system'),
    tax: t('Tax'),
    total: t('Total'),
    totalAmount: t('Total amount'),
    totalFee: t('Total fee'),
    totalPurchase: t('Total Purchase'),
    chargedAmount: t('Charged amount'),
    totalSales: t('Total sales'),
    totalTreatmentFee: t('Total treatment fee'),
    transferAmount: t('Transfer amount'),
    treatmentDate: t('Treatment date'),
    tipFor: t('Tip for'),
    tipForAllStaff: t('Tip for all staff'),
    imageForBankProof: t('ImageForBankProof'),
    normal: t('Normal'),
    saving: t('Saving'),
    basicInformation: t('BasicInformation'),
    salonProperties: t('SalonProperties'),
    salonImages: t('SalonImages'),
    salonDescription: t('SalonDescription'),
    salonLogoExtraUpload: t('SalonLogoExtraUpload'),
    salonImagesExtraUpload: t('SalonImagesExtraUpload'),
    recommendedService: t('RecommendedService'),
    saveAndPublishServices: t('SaveAndPublishServices'),
    recommended: t('Recommended'),
    recommendedPlaceholder: t('RecommendedPlaceholder'),
    acceptanceTimeLabel: t('AcceptanceTimeLabel'),
    acceptanceDateLabel: t('AcceptanceDateLabel'),
    acceptanceTimeContent: t('AcceptanceTimeContent'),
    cancellationCharge: t('CancellationCharge'),
    payoutCycle: t('Payout cycle'),
    bookingFee: t('Booking fee'),
    settlementFee: t('SettlementFee'),
    transferFee: t('Transfer fee'),
    transferDate: t('Transfer date'),
    subtotalSales: t('SubtotalSales'),
    transferAmountSales: t('TransferAmountSales'),
    scheduledTransfer: t('Scheduled transfer'),
    transferDone: t('Transfer done'),
    menu: t('Menu'),
    employer: t('Employer'),
    deadline: t('Deadline'),
    confirmArriveOnTime: t('ConfirmArriveOnTime'),
    additionalInfor: t('AdditionalInfor'),
    nailieBookedNote: t('NailieBookedNote'),
    delayedNote: t('DelayedNote'),
    canceledNote: t('CanceledNote'),
    times: t('Times'),
    retry: t('Retry'),
    csConnectStaffTitle: t('CSConnectStaffTitle'),
    csConnectStaffWarningHead: t('CsConnectStaffWarningHead'),
    CsConnectStaffWarningTail: t('CsConnectStaffWarningTail'),
    salonStaff: t('SalonStaff'),
    comingSoonStaff: t('ComingSoonStaff'),
    selectStaffPlaceholder: t('SelectStaffPlaceholder'),
    disconnectThirdParty: t('DisconnectThirdParty'),
    warningCompleteMapping: t('WarningCompleteMapping'),
    inputTokenCSError: t('InputTokenCSError'),
    salonCommon: t('SalonCommon'),
    nailistOnly: t('NailistOnly'),
    eyelistOnly: t('EyelistOnly'),

    // Status
    new: t('New'),
    arrived: t('Arrived'),
    started: t('Started'),
    done: t('Done'),
    active: t('Active'),
    inActive: t('InActive'),
    disconnected: t('Inviting'),
    inviting: t('Inviting'),
    waitingForConfirm: t('WaitingForConfirm'),
    rejected: t('Rejected'),
    expired: t('Expired'),
    canceled: t('Canceled'),
    cancelInvitation: t('CancelInvitation'),
    resendInvitation: t('ResendInvitation'),
    waitingForPayment: t('Waiting for payment'),
    success: t('Success'),
    error: t('Error'),
    refund: t('Refund'),
    disabled: t('Disabled'),
    navigationAccess: t('NavigationAccess'),
    walkingTime: t('WalkingTime'),
    nearestStation: t('NearestStation'),
    nearestStationExtraInfo1: t('NearestStationExtraInfo1'),
    nearestStationExtraInfo2: t('NearestStationExtraInfo2'),
    minutesWakingTime: t('MinutesWalkingTime'),
    updateStationPopupTitle: t('UpdateStationPopupTitle'),
    updateStationPopupContent: t('UpdateStationPopupContent'),
    updatePropertiesPopupTitle: t('UpdatePropertiesPopupTitle'),
    updatePropertiesPopupContent: t('UpdatePropertiesPopupContent'),
    updateStationPopupOkText: t('UpdateStationPopupOkText'),
    updateStationPopupCancelText: t('UpdateStationPopupCancelText'),
    staffInfo: t('StaffInfo'),
    staffInforSearchPlaceholder: t('StaffInforSearchPlaceholder'),

    // Payment status
    paymentStatusWaiting: t('PaymentStatusWaiting'),
    paymentStatusSucceeded: t('PaymentStatusSucceeded'),
    paymentStatusError: t('PaymentStatusError'),
    paymentStatusCanceled: t('PaymentStatusCanceled'),
    paymentStatusRefunded: t('PaymentStatusRefunded'),

    // Payment Method
    paymentMethodOnline: t('PaymentMethodOnline'),
    paymentMethodAtone: t('PaymentMethodAtone'),
    paymentMethodBankTransfer: t('PaymentMethodBankTransfer'),

    // Nailie booking status
    statusConfirmed: t('StatusConfirmed'),
    statusDone: t('StatusDone'),
    statusWaitingDone: t('StatusWaitingDone'),
    statusDeclinedReservation: t('StatusDeclinedReservation'),
    statusCanceled: t('StatusCanceled'),
    statusNailistCanceled: t('StatusNailistCanceled'),
    statusCardErrorCancel: t('StatusCardErrorCancel'),
    statusCanceledReservation: t('StatusCanceledReservation'),
    statusNoVisit: t('StatusNoVisit'),
    statusUndone: t('StatusUndone'),
    statusNailieCanceled: t('StatusNailieCanceled'),
    statusDeleted: t('StatusDeleted'),

    // Option
    female: t('Female'),
    male: t('Male'),
    card: t('Card'),
    cash: t('Cash'),
    badDesign: t('Bad design'),
    badServices: t('Bad services'),
    customerUnsatisfied: t('Customer unsatisfied'),
    hair: t('Hair'),
    massage: t('Massage'),
    all: t('All'),
    nail: t('Nail'),
    eye: t('Eye'),
    spa: t('Spa'),
    noFee: t('No fee'),
    halfFee: t('Charge 50% booking fee'),
    wholeFee: t('Charge 100% booking fee'),
    personal: t('Personal'),
    company: t('Company'),
    cancellationType: t('CancellationType'),
    customerCancel: t('CustomerCancel'),
    nailistCancel: t('NailistCancel'),
    back: t('Back'),
    emergencyCanCel: t('EmergencyCanCel'),
    emergencyCanCelTitle: t('EmergencyCanCelTitle'),
    emergencyCanCelDescription: t('EmergencyCanCelDescription'),
    emergencyReasonExample: t('EmergencyReasonExample'),
    emergencyAttentionNote: t('EmergencyAttentionNote'),
    emergencyConfirmSelect: t('EmergencyConfirmSelect'),
    messageToCustomerNote: t('MessageToCustomerNote'),
    messageToNaileOperator: t('MessageToNaileOperator'),
    messageToOperatorNote: t('MessageToOperatorNote'),
    messageToOurCustomerConfirmed: t('MessageToOurCustomerConfirmed'),
    messageToAdminOperatorConfirmed: t('MessageToAdminOperatorConfirmed'),
    emptyEmergencyPolicy: t('EmptyEmergencyPolicy'),
    emergencyCancelConfirmTitle: t('EmergencyCancelConfirmTitle'),

    nailistCanCelTitle: t('NailistCanCelTitle'),
    nailistCanCelDescription: t('NailistCanCelDescription'),
    nailistAttentionNote: t('NailistAttentionNote'),
    reviewImage: t('ReviewImage'),
    automaticPostingTitle: t('AutomaticPostingTitle'),
    automaticPostingContent: t('AutomaticPostingContent'),
    nailistCancelConfirmContent: t('NailistCancelConfirmContent'),
    firstPeriod: t('First period'),
    secondPeriod: t('Second period'),
    fullMonth: t('Full month'),
    allow: t('Allow'),
    prevent: t('Prevent'),
    inputTokenCSPlaceholder: t('InputTokenCSPlaceholder'),

    // Placeholder
    fromDate: t('From date'),
    toDate: t('To date'),
    searchBookingPlaceholder: t('SearchBookingPlaceholder'),
    searchPaymentPlaceholder: t('SearchPaymentPlaceholder'),
    searchStaffPlaceholder: t('SearchStaffPlaceholder'),
    searchStaffOverviewPlaceholder: t('SearchStaffOverviewPlaceholder'),
    searchStaffSaleReportPlaceholder: t('SearchStaffSaleReportPlaceholder'),
    selectSystem: t('Select a system'),
    systemKey: t('System key'),
    refundReasonPlaceholder: t('RefundReasonPlaceholder'),
    tipsForStaffPlaceholder: t('TipsForStaffPlaceholder'),
    menuDetailPlaceholder: t('MenuDetailPlaceholder'),
    emptyStateServicesMessage: t('EmptyStateServicesMessage'),
    emergencyCancelContent: t('EmergencyCancelContent'),
    nailistCancelContent: t('NailistCancelContent'),
    cancellationDescription: t('CancellationDescription'),
    customerCancelContent: t('CustomerCancelContent'),
    messageToCustomer: t('MessageToCustomer'),
    messageWithEmergencyReason: t('MessageWithEmergencyReason'),
    salonDescriptionPlaceholder: t('SalonDescriptionPlaceholder'),
    inputMemoPlaceholder: t('InputMemoPlaceholder'),
    messageToCustomerPlaceholder: t('MessageToCustomerPlaceholder'),
    messageToOperatorPlaceholder: t('MessageToOperatorPlaceholder'),

    // Modal
    confirm: t('Confirm'),
    confirmCancel: t('ConfirmCancel'),
    confirmCancelBilling: t('ConfirmCancelBilling'),
    confirmCancelBooking: t('ConfirmCancelBooking'),
    ConfirmCompleteBooking: t('ConfirmCompleteBooking'),
    confirmCancelInformation: t('ConfirmCancelInformation'),
    confirmDeleteCategory: t('ConfirmDeleteCategory'),
    confirmDeleteService: t('ConfirmDeleteService'),
    confirmDeleteStaffTitle: t('ConfirmDeleteStaffTitle'),
    confirmDeleteStaffContent: t('ConfirmDeleteStaffContent'),
    confirmDeleteEmail: t('ConfirmDeleteEmail'),
    confirmLogout: t('ConfirmLogout'),
    confirmResend: t('ConfirmResend'),
    receiveEmail: t('Receive email'),
    clickEmailLink: t('Click on the email link'),
    enterNewPassword: t('Enter new password'),
    checkStaffInvitationErrorTitle: t('CheckStaffInvitationErrorTitle'),
    checkStaffInvitationErrorContent: t('CheckStaffInvitationErrorContent'),
    nailistUsernameNotFound: t('NailistUsernameNotFound'),
    underConstruction: t('UnderConstruction'),
    openSlot: t('OpenSlot'),
    openSlots: t('OpenSlots'),
    closeSlot: t('CloseSlot'),
    closeSlots: t('CloseSlots'),
    outsideBookingsModalTitleHead: t('OutsideBookingsModalTitleHead'),
    outsideBookingsModalTitleTail: t('OutsideBookingsModalTitleTail'),
    outsideBookingsModalContent: t('OutsideBookingsModalContent'),
    addServicesForStaffConfirmContent: t('AddServicesForStaffConfirmContent'),
    updateServiceStatusModalContentHead: t('UpdateServiceStatusModalContentHead'),
    updateServiceStatusModalContentTail: t('UpdateServiceStatusModalContentTail'),
    staffWorkingTimeViewModalContentHead: t('StaffWorkingTimeViewModalContentHead'),
    staffWorkingTimeViewModalContentTail: t('StaffWorkingTimeViewModalContentTail'),
    staffWorkingTimeFooter: t('StaffWorkingTimeFooter'),
    latestScheduleHead: t('LatestScheduleHead'),
    latestScheduleTail: t('LatestScheduleTail'),
    pastScheduleHead: t('PastScheduleHead'),
    pastScheduleTail: t('PastScheduleTail'),
    clickHereEnglish: t('ClickHereEnglish'),
    clickHereJapanese: t('ClickHereJapanese'),
    completeBookingInstruction: t('CompleteBookingInstruction'),
    changeBookingDateModalTitle: t('ChangeBookingDateModalTitle'),
    changeBookingDateModalNotes: t('ChangeBookingDateModalNotes'),
    iCheckedNotes: t('I checked notes'),
    no: t('No'),
    yes: t('Yes'),
    sure: t('Sure'),
    editImage: t('EditImage'),
    connect: t('Connect'),
    connectWithCSTitle: t('ConnectWithCSTitle'),
    tokenLabel: t('Token'),
    connectCSInputTokenDescription: t('ConnectCSInputTokenDescription'),
    beautyMeritContentHead: t('BMContentHead'),
    beautyMeritContentTail: t('BMContentTail'),
    comingSoonContentHead: t('CSContentHead'),
    bmContentDisconnect1: t('BMContentDisconnect1'),
    bmContentDisconnect2: t('BMContentDisconnect2'),
    bmContentConnect: t('BMContentConnect'),
    bmDisConnectAlert: t('BMDisConnectAlert'),
    csDisConnectAlert: t('CSDisConnectAlert'),
    csContentDisconnect1: t('CSContentDisconnect1'),
    csContentDisconnect2: t('CSContentDisconnect2'),
    csContentConnect: t('CSContentConnect'),
    comingSoonContentTail: t('CSContentTail'),
    contentConfirmSyncScheduleHead: t('ContentConfirmSyncScheduleHead'),
    contentConfirmSyncBeautyMeritScheduleTail: t('ContentConfirmSyncBeautyMeritScheduleTail'),
    contentConfirmSyncComingSoonScheduleTail: t('ContentConfirmSyncComingSoonScheduleTail'),
    comingSoon: t('ComingSoonTittle'),
    beautyMerit: t('BeautyMeritTitle'),
    externalParties: t('ExternalParties'),
    sendTheUpdate: t('Send the update'),
    confirmationPopup: t('Confirmation Popup'),
    appliedNailistScheduleHead: t('AppliedNailistScheduleHead'),
    appliedNailistScheduleTail: t('AppliedNailistScheduleTail'),
    connectedWithCS: t('ConnectedWithCS'),
    procedureLinkCS: t('ProcedureLinkCS'),
    connectCSstep1: t('ConnectCSstep1'),
    connectCSstep: t('ConnectCSstep'),
    connectCSstepLine1: t('ConnectCSstepLine1'),
    connectCSstepLine2: t('ConnectCSstepLine2'),
    connectCSstep2: t('ConnectCSstep2'),
    connectCSstepLine3: t('ConnectCSstepLine3'),
    connectCSstepLine4: t('ConnectCSstepLine4'),
    connectCSstepLine5: t('ConnectCSstepLine5'),
    connectCSstepNoteContent: t('ConnectCSstepNoteContent'),
    connectedWithBM: t('ConnectedWithBM'),
    disconnectWithBMBtn: t('DisconnectWithBMBtn'),
    disconnectWithCSBtn: t('DisconnectWithCSBtn'),
    howToConnectBM: t('HowToConnectBM'),
    turnOnSwitchButton: t('TurnOnSwitchButton'),
    bmConnectSteps: t('BMConnectSteps'),
    beautyMeritDescription1: t('BeautyMeritDescription1'),
    followProgressSetting: t('FollowProgressSetting'),
    bmStepLine1: t('BMStepLine1'),
    bmStepLine2: t('BMStepLine2'),
    bmStepLine3: t('BMStepLine3'),
    bmNoteLine1: t('BMNoteLine1'),
    bmNoteLine2: t('BMNoteLine2'),
    bmNoteLine3: t('BMNoteLine3'),
    bmNoteLine4: t('BMNoteLine4'),
    bmNoteLine5: t('BMNoteLine5'),
    warningDisconnectOther3rdPartyTitle: t('WarningDisconnectOther3rdPartyTitle'),
    warningDisconnectOther3rdPartyHead: t('WarningDisconnectOther3rdPartyHead'),
    warningDisconnectOther3rdPartyTail: t('WarningDisconnectOther3rdPartyTail'),
    scheduleWarningWhenConnected3rd: t('ScheduleWarningWhenConnected3rd'),
    mappingPopupTitle: t('MappingPopupTitle'),
    changeEmailAddress: t('ChangeEmailAddress'),
    changeSalonEmail: t('ChangeSalonEmail'),
    changeSalonEmailModalTitle: t('ChangeSalonEmailModalTitle'),
    changeEmailInput: t('ChangeEmailInput'),
    changeEmailStep1: t('ChangeEmailStep1'),
    changeEmailStep2: t('ChangeEmailStep2'),
    changeEmailStep3: t('ChangeEmailStep3'),
    changeEmailWarning: t('ChangeEmailWarning'),
    changedEmailAlertContent1: t('ChangedEmailAlertContent1'),
    changedEmailAlertContent2: t('ChangedEmailAlertContent2'),
    // Alert
    ChangeBookingDateSuccess: t('ChangeBookingDateSuccess'),

    // Login
    login: t('Log in'),
    requestAccount: t('Request account'),
    backToLogin: t('Back to login'),
    haveAccount: t('Already have an account?'),
    requestAccountSalonSuccessMessage: t('RequestAccountSalonSuccessMessage'),
    headTermsOfServices: t('HeadTermsOfServices'),
    termsOfServices: t('TermsOfServices'),
    tailTermsOfServices: t('TailTermsOfServices'),

    // Time
    week: t('Week'),
    day: t('Day'),
    hour: t('Hour'),
    min: t('min'),
    hours: t('Hours'),
    minutes: t('Minutes'),
    seconds: t('Seconds'),
    monday: t('Monday'),
    tuesday: t('Tuesday'),
    wednesday: t('Wednesday'),
    thursday: t('Thursday'),
    friday: t('Friday'),
    saturday: t('Saturday'),
    sunday: t('Sunday'),
    twentyFourHours: t('TwentyFourHours'),
    oneDay: t('One day'),
    severalDays: t('Several days'),

    // Other
    welcome: t('Welcome'),
    loading: t('Loading'),
    systemInvitationDescription: t('SystemInvitationDescription'),
    sessionExpired: t('SessionExpired'),
    manageSalonAccount: t('ManageSalonAccount'),
    optional: t('Optional'),
    off: t('OFF'),
    salonDescriptionExtra: t('SalonDescriptionExtra'),
    required: t('Required'),
    case: t('Case'),
    reservationAlertBtn: t('ReservationAlertBtn'),
    reservationAlertTitle: t('ReservationAlertTitle'),
    reservationAlertMessage: t('ReservationAlertMessage'),
    notificationMessageConfirm: t('NotificationMessageConfirm'),
    notificationMessageExpired: t('NotificationMessageExpired'),
    notificationMessageCanceled: t('NotificationMessageCanceled'),
    notificationMessageNovisit: t('NotificationMessageNovisit'),
    titleStatusWaitingDone: t('TitleStatusWaitingDone'),
    WaitingDoneBookingWarning: t('WaitingDoneBookingWarning'),
    WaitingDoneBookingWarningImportant: t('WaitingDoneBookingWarningImportant'),
    contentDisconnect3rdParty: t('ContentDisconnect3rdParty'),
    contentDisconnect3rdPartyHead: t('ContentDisconnect3rdPartyHead'),
    contentDisconnect3rdPartyTail: t('ContentDisconnect3rdPartyTail'),
    unlink: t('Unlink'),
    bookingConfirmedDate: t('BookingConfirmedDate'),

    // Error
    emptyCancelReasonError: t('EmptyCancelReasonError'),
    emptyCancelMessageError: t('EmptyCancelMessageError'),
    emptyCancelMessageOperatorError: t('EmptyCancelMessageOperatorError'),
    emptyCancellationFeeError: t('EmptyCancellationFeeError'),
    emptyBookingDateError: t('EmptyBookingDateError'),
    emptyStatusError: t('EmptyStatusError'),
    emptyStartTimeError: t('EmptyStartTimeError'),
    emptyEndTimeError: t('EmptyEndTimeError'),
    emptyServiceError: t('EmptyServiceError'),
    emptyStaffError: t('EmptyStaffError'),
    emptyStaffProfilePicture: t('EmptyStaffProfilePicture'),
    emptyStaffNameError: t('EmptyStaffNameError'),
    emptyUserNameError: t('EmptyUserNameError'),
    emptyCustomerError: t('EmptyCustomerError'),
    emptyNameError: t('EmptyNameError'),
    emptyCustomerNameError: t('EmptyCustomerNameError'),
    emptyPhoneNumberError: t('EmptyPhoneNumberError'),
    emptyPhoneticError: t('EmptyPhoneticError'),
    emptyRefundReasonError: t('EmptyRefundReasonError'),
    emptyBusinessNameError: t('EmptyBusinessNameError'),
    emptyPostCodeError: t('EmptyPostCodeError'),
    emptyBuildingRoomError: t('EmptyBuildingRoomError'),
    emptySalonAreaError: t('EmptySalonAreaError'),
    emptyAddressModeError: t('EmptyAddressModeError'),
    emptyAddressTownError: t('EmptyAddressTownError'),
    emptyAddressError: t('EmptyAddressError'),
    emptyEmailError: t('EmptyEmailError'),
    emptyBankNameError: t('EmptyBankNameError'),
    emptyBranchNameError: t('EmptyBranchNameError'),
    emptyAccountTypeError: t('EmptyAccountTypeError'),
    emptyAccountNumberError: t('EmptyAccountNumberError'),
    emptyAccountNameError: t('EmptyAccountNameError'),
    emptyImageForBankProofError: t('EmptyImageForBankProofError'),
    emptyServiceNameError: t('EmptyServiceNameError'),
    emptyServiceCategoryError: t('EmptyServiceCategoryError'),
    emptyDurationError: t('EmptyDurationError'),
    emptyPriceError: t('EmptyPriceError'),
    emptyNailieUsernameError: t('EmptyNailieUsernameError'),
    emptySystemError: t('EmptySystemError'),
    emptySystemKeyError: t('EmptySystemKeyError'),
    emptyBusinessHourStartTimeError: t('EmptyBusinessHourStartTimeError'),
    emptyBusinessHoureEndTimeError: t('EmptyBusinessHoureEndTimeError'),
    invalidEmailError: t('InvalidEmailError'),
    invalidPhoneNumberError: t('InvalidPhoneNumberError'),
    minLengthPhoneNumberError: t('MinLengthPhoneNumberError'),
    minBirthdayError: t('MinBirthdayError'),
    mustInput7Character: t('MustInput7Character'),
    minLength1Error: t('MinLength1Error'),
    maxLength7Error: t('MaxLength7Error'),
    maxLength50Error: t('MaxLength50Error'),
    duplicatedEmailError: t('DuplicatedEmailError'),
    invalidAccountNumber: t('InvalidAccountNumber'),
    invalidAccountName: t('InvalidAccountName'),
    theStaffServing: t('TheStaffServing'),
    isChanged: t('IsChanged'),
    validateDiscountError: t('ValidateDiscountError'),
    completeBookingMemoHelp: t('CompleteBookingMemoHelp'),
    completeBookingMemoError: t('CompleteBookingMemoError'),
    completeBookingSubtotalEmphasize: t('CompleteBookingSubtotalEmphasize'),
    completeBookingSubtotalErrorHead: t('CompleteBookingSubtotalErrorHead'),
    completeBookingSubtotalErrorTail: t('CompleteBookingSubtotalErrorTail'),

    // Warning
    workingDayWarning: t('WorkingDayWarning'),
    businessHourWarning: t('BusinessHourWarning'),

    // Prompt
    minPriceExtraPromptMessageHead: t('MinPriceExtraPromptMessageHead'),
    minPriceExtraPromptMessageTail: t('MinPriceExtraPromptMessageTail'),
    maxPriceExtraPromptMessageHead: t('MaxPriceExtraPromptMessageHead'),
    maxPriceExtraPromptMessageTail: t('MaxPriceExtraPromptMessageTail'),
    photoExtraPromptMessage: t('PhotoExtraPromptMessage'),
  };
};
