import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      Welcome: 'Hello',
      'My Salon': 'My Salon',
      Bookings: 'Bookings',
      Payment: 'Payment',
      Staff: 'Staff',
      Report: 'Report',
      Profile: 'Profile',
      Schedule: 'Schedule',
      CancellationPolicy: 'Cancellation Policy',
      CancellationPolicyNote1: 'This will take effect immediately for up coming bookings.',
      CancellationPolicyNote2: 'It does not effect your existing bookings.',
      'Change Password': 'Change Password',
      'Account settings': 'Account settings',
      'Salon Account': 'Salon Account',
      Logout: 'Logout',
      MenuSettings: 'Menu settings',
      Services: 'Services',
      Customers: 'Customers',
      Settings: 'Settings',
      'Staff Schedule': 'Staff Schedule',
      'Publish Schedule': 'Publish schedule',
      'Staff Member': 'Staff Member',
      'Staff Overview': 'Staff Overview',
      'Staff name': 'Staff name',
      Name: 'Name',
      FullName: 'Full name',
      Avatar: 'Avatar',
      Phonetic: 'Phonetic',
      Email: 'Email',
      'Email address': 'Email address',
      Phone: 'Phone',
      PersonalPhoneNumber: 'Personal phone number',
      Status: 'Status',
      Gender: 'Gender',
      Male: 'Male',
      Female: 'Female',
      Prefectures: 'Prefectures',
      'Post Code': 'Post Code',
      City: 'City',
      Area: 'Area',
      Address: 'Address',
      'Building name': 'Building name',
      'Address mode': 'Address mode',
      'Always show salon address': 'Always show salon address',
      'Show salon address only to the confirmed customer': 'Show salon address only to the confirmed customer',
      'Select an option that you want to public salon address or not': 'Select an option that you want to public salon address or not',
      'Salon area': 'Salon area',
      'Select Salon Area': 'Select Salon Area',
      Region: 'Region',
      Prefecture: 'Prefecture',
      'room number': 'room number',
      'City or Town': 'City or Town',
      'Business Type': 'Business Type',
      'Business name': 'Business name',
      Logo: 'Logo',
      Change: 'Change',
      Nail: 'Nail',
      Hair: 'Hair',
      Massage: 'Massage',
      Spa: 'Spa',
      BasicInformation: 'Basic information',
      SalonProperties: 'Salon properties',
      SalonImages: 'Salon images',
      SalonDescription: 'Salon description',
      SalonDescriptionPlaceholder: 'Introduce your salon here',
      SalonDescriptionExtra: '※Please refrain from posting contact information such as LINE and phone numbers, and guiding to SNS and other services.',
      SalonLogoExtraUpload: 'You can upload up to an image of 5MB',
      SalonImagesExtraUpload: 'You can upload up to 3 images of 5MB',
      'Update and Publish': 'Update and Publish',
      Continue: 'Continue',
      'Continue to complete your Salon schedule': 'Continue to complete your Salon schedule',
      'Continue to complete your Salon settings to start your business': 'Continue to complete your Salon settings to start your business',
      SearchStaffPlaceholder: 'Staff Name,email,phone',
      SearchBookingPlaceholder: 'Search Booking ID, Customer, Staff',
      SearchPaymentPlaceholder: 'Search Payment ID, BookingID, Customer name, Customer pay',
      SearchStaffOverviewPlaceholder: 'Search Booking ID, Customer, Nailist',
      SearchStaffSaleReportPlaceholder: 'Search staff name',
      'From date': 'From date',
      'To date': 'To date',
      Password: 'Password',
      'Message (optional)': 'Message (optional)',
      Birthday: 'Birthday',
      'Add Category': 'Add Category',
      'Add Menu': 'Add Menu',
      'Add date': 'Add date',
      'Add Staff': 'Add Staff',
      'Add Schedule': 'Add Schedule',
      'Add Discount': 'Add Discount',
      'Add Customer': 'Add Customer',
      'Update Category': 'Update Category',
      'Update Service': 'Update Service',
      'Update Menu': 'Update Menu',
      'Create Booking': 'Create Booking',
      'Edit Banking': 'Edit Banking',
      'Add Tips': 'Add Tips',
      'Remove tips': 'Remove tips',
      'Tip for': 'Tip for',
      'Tip for all staff': 'Tip for all staff',
      'Cancel Booking': 'Cancel Booking',
      UnableCompleteTreatment: 'Click here if you were unable to complete your treatment',
      'Update Booking': 'Update Booking',
      'Edit Customer': 'Edit Customer',
      'Update Customer': 'Update Customer',
      'Edit Account': 'Edit Account',
      'Edit Account Info': 'Edit Account Info',
      'Update Bank Account Info': 'Update Bank Account Info',
      'Edit Bank Info': 'Edit Bank Info',
      Active: 'Active',
      Inactive: 'Inactive',
      Disconnected: 'Disconnected',
      Inviting: 'Inviting',
      'Personal Info': 'Personal Info',
      'Banking Account': 'Banking Account',
      Branch: 'Branch',
      'Bank name': 'Bank name',
      'Branch name': 'Branch name',
      'Account type': 'Account type',
      'Account number': 'Account number',
      'Account name': 'Account name',
      BankbookTitle: 'Please register your bankbook for operator to confirm',
      BankbookDescription1: 'Please prepare 1st page of your bank book.',
      BankbookDescription2: 'Upload page with account type, account name, account number, bank name, bank branch.',
      'Category Name': 'Category Name',
      'Category Description': 'Category Description',
      'Payment report': 'Payment report',
      'Billing count': 'Billing count',
      'Online payment': 'Online payment',
      'Total amount': 'Total amount',
      'Menu Category': 'Menu category',
      'Menu Name': 'Menu name',
      Price: 'Price',
      IncludedTax: 'tax incl.',
      PriceIncludedTax: 'Price (Tax included)',
      Description: 'Description',
      Duration: 'Duration',
      min: 'min',
      Tips: 'Tips',
      'All staff': 'All staff',
      Billing: 'Billing',
      Cash: 'Cash',
      Card: 'Card',
      'Customer ID': 'Customer ID',
      Date: 'Date',
      'Customer unsatisfied': 'Customer unsatisfied',
      'Bad design': 'Bad design',
      'Bad services': 'Bad services',
      'Cancellation fee': 'Cancellation fee',
      'Booking Detail': 'Booking Detail',
      'Booking ID': 'Booking ID',
      'Treatment date': 'Treatment date',
      'Customer Detail': 'Customer Detail',
      'Create Date': 'Create Date',
      'Total Purchase': 'Total Purchase',
      Ranking: 'Ranking',
      'Last Visit Date': 'Last Visit Date',
      'Customer name': 'Customer name',
      'Total fee': 'Total fee',
      System: 'System',
      'Status update': 'Status update',
      'Payment ID': 'Payment ID',
      'Created date': 'Created date',
      'Payment method': 'Payment method',
      'Target system': 'Target system',
      'Customer pay': 'Customer pay',
      Estimate: 'Estimate',
      RankingBronze: 'RANK BRONZE',
      RankingSilver: 'RANK SILVER',
      RankingGold: 'RANK GOLD',
      RankingPlatinum: 'RANK PLATINUM',
      RankingDiamond: 'RANK DIAMOND',
      'Payment Detail': 'Payment Detail',
      'Payment Info': 'Payment Info',
      'Customer Info': 'Customer Info',
      'Cash payment': 'Cash payment',
      Subtotal: 'Subtotal',
      UseCoupon: 'Coupon',
      UsePoint: 'Points',
      Tax: 'Tax',
      Total: 'Total (Salon sales)',
      'Booking Date': 'Booking Date',
      BookingTime: 'Time',
      Customer: 'Customer',
      'New Customer': 'New Customer',
      'Start Time': 'Start Time',
      'Quick Payment': 'Quick Payment',
      'Discount Cash': 'Discount Cash',
      'Process payment': 'Process payment',
      'Refund reason': 'Refund reason',
      Reason: 'Reason',
      Note: 'Note',
      'Staff Profile': 'Staff Profile',
      'Upload Photo': 'Upload Photo',
      'Edit Profile': 'Edit Profile',
      RemoveStaff: ' Remove Staff',
      Rating: 'Rating',
      Timesheet: 'Timesheet',
      Salary: 'Salary',
      Review: 'Review',
      'Banking Information': 'Banking Information',
      'Bank Name': 'Bank Name',
      'Bank branch': 'Bank branch',
      Complete: 'Complete',
      Import: 'Import',
      'Import from Nailie': 'Import from Nailie',
      'Nailie username': 'Nailie username',
      UserName: 'Username',
      CheckStaffInvitationErrorTitle: 'Staff already exists',
      CheckStaffInvitationErrorContent: 'Please import another staff!',
      EmailSettingDescription: 'For certain booking update(Confirmed, Cancelled) and Input bank info, sending email to those emails:',
      'Label name': 'Label name',
      'Default Label': 'Default',
      Add: 'Add',
      Delete: 'Delete',
      Edit: 'Edit',
      Search: 'Search',
      Export: 'Export',
      ExportCSV: 'Export CSV',
      Home: 'Home',
      'Search Result': 'Search Result',
      'Waiting for payment': 'Waiting for payment',
      Success: 'Success',
      Error: 'Error',
      'Bank tranfer': 'Bank tranfer',
      New: 'New',
      Arrived: 'Arrived',
      Started: 'Started',
      Done: 'Done',
      StatusConfirmed: 'CONFIRMED',
      StatusDone: 'DONE',
      ReservationAlertBtn: 'Reservation alert',
      ReservationAlertTitle: 'Alert information',
      ReservationAlertMessage: 'You has seen all notifications right now',
      NotificationMessageConfirm: 'The reservation has been confirmed.',
      NotificationMessageExpired: 'The payment has expired. The secretariat has been cancelled.',
      NotificationMessageCanceled: 'The reservation has been cancelled.',
      NotificationMessageNovisit: 'Although the reservation time has passed, we could not confirm the visit, so we canceled it on the day.',
      TitleStatusWaitingDone: 'Waiting done',
      StatusWaitingDone: 'WAITING_DONE',
      StatusDeclinedReservation: '(N) DECLINED_RESERVATION',
      StatusNailistCanceled: 'NAILIST_CANCELED',
      StatusCardErrorCancel: 'CARD_ERROR_CANCEL',
      StatusCanceledReservation: '(C) CANCELED_RESERVATION',
      StatusCanceled: 'CANCELED',
      StatusNoVisit: 'NOVISIT',
      StatusUndone: 'EMERGENCY_CANCELED',
      StatusNailieCanceled: 'PAYMENT_EXPIRED',
      StatusDeleted: 'DELETED',
      PaymentStatusWaiting: 'Waiting for payment',
      PaymentStatusSucceeded: 'Paid',
      PaymentStatusError: 'Payment Error',
      PaymentStatusCanceled: 'Payment Canceled',
      PaymentStatusRefunded: 'Refunded',
      PaymentMethodOnline: 'Online payment',
      PaymentMethodAtone: 'Atone',
      PaymentMethodBankTransfer: 'Bank transfer',
      PaymentSummary: 'Payment Summary',
      Cancel: 'Cancel',
      Save: 'Save',
      Confirm: 'Confirm',
      Refund: 'Refund',
      Action: 'Action',
      Copy: 'Copy',
      No: 'No',
      Yes: 'Yes',
      Sure: 'Sure',
      Select: 'Please select',
      SelectedOption: 'Select',
      EditPlaceOfPin: 'Edit place of the pin',
      MapSettings: 'Map settings',
      MapSettingsExtra: 'You can change the pin position by clicking on the map',
      'No fee': 'No fee',
      'Charge 50% booking fee': 'Charge 50% booking fee',
      'Charge 100% booking fee': 'Charge 50% booking fee',
      Loading: 'Loading',
      'Log in': 'Log in',
      'Request account': 'Request account',
      'Back to login': 'Back to login',
      'Already have an account?': 'Already have an account?',
      RequestAccountSalonSuccessMessage: 'You will receive a confirmation email shortly.\n\nPlease wait for the Salon System Manager to approve your request.',
      HeadTermsOfServices: 'I agree to ',
      TermsOfServices: 'Terms of Services',
      TailTermsOfServices: '',
      ConfirmDeleteCategory: 'Are you sure to delete the category?',
      ConfirmDeleteService: 'Are you sure to delete the service?',
      ConfirmCancelBooking: 'Are you sure you want to cancel this booking?',
      ConfirmCompleteBooking: 'Are you sure you want to complete this booking?',
      ConfirmCancelInformation: 'This information will be canceled?',
      ConfirmCancelBilling: 'This billing  will be canceled?',
      ConfirmLogout: 'Do you really want to log out?',
      ConfirmDeleteStaffTitle: 'Are you sure to delete this Staff?',
      ConfirmDeleteStaffContent: 'Nailist account will be disconnect but remain active in Nailie app!',
      ConfirmDeleteEmail: 'Are you sure to want to delete this email?',
      'Change password Process': 'Change Password Process',
      'Receive email': 'Receive email',
      'Click on the email link': 'Click on the email link',
      'Enter new password': 'Enter new password',
      EmptyCancellationFeeError: 'Please select a cancellation fee!',
      EmptyBookingDateError: 'Please input the booking Date!',
      EmptyStatusError: 'Please select a status!',
      EmptyStartTimeError: 'Start time is required!',
      EmptyEndTimeError: 'End time is required!',
      EmptyServiceError: 'Missing service!!!',
      EmptyStaffError: 'Missing staff!!!',
      EmptyStaffProfilePicture: 'Please upload profile picture',
      EmptyStaffNameError: 'Please input your Staff name!',
      EmptyUserNameError: 'Please input Username!',
      EmptyCustomerError: 'Please select your customer!',
      EmptyNameError: 'Please input the Name!',
      EmptyCustomerNameError: 'Please input the Customer name!',
      EmptyPhoneNumberError: 'Please input the Phone Number!',
      EmptyPhoneticError: 'Please input hiragana',
      EmptyRefundReasonError: 'Please select refund reason!',
      EmptyBusinessNameError: 'Please input your Business Name!',
      EmptyPostCodeError: 'Please input a Post Code!',
      EmptyBuildingRoomError: 'Please input a Building Name/ Room number!',
      EmptySalonAreaError: 'Please select a salon area!',
      EmptyAddressModeError: 'Please select an address mode!',
      EmptyAddressTownError: 'Please input an town name!',
      EmptyAddressError: 'Please input an address!',
      EmptyEmailError: 'Please input E-mail!',
      EmptyBankNameError: 'Please select a Bank Name!',
      EmptyBranchNameError: 'Please select a Branch Name!',
      EmptyAccountTypeError: 'Please select an Account Type!',
      EmptyAccountNumberError: 'Please input an Account Number!',
      EmptyAccountNameError: 'Please input an Account Name!',
      EmptyImageForBankProofError: 'Please upload a photo！',
      EmptyServiceNameError: 'Please input the Menu name!',
      EmptyServiceCategoryError: 'Please select a Menu Category!',
      EmptyDurationError: 'Please select a Duration!',
      EmptyPriceError: 'Please input the Price!',
      EmptyNailieUsernameError: 'Please input Nailist phone number or email!',
      EmptySystemError: 'Please select a system!',
      EmptySystemKeyError: 'Please input a system key!',
      EmptyBusinessHourStartTimeError: 'Please input business hour Start Time!',
      EmptyBusinessHoureEndTimeError: 'Please input business hour End Time!',
      InvalidEmailError: 'The input is not valid Email !',
      InvalidPhoneNumberError: 'Not a valid Phone Number',
      MinLengthPhoneNumberError: 'Please type 10 or 11 numbers. Don\'t include (-)',
      MinBirthdayError: 'The Birthday must be greater than 18 years!',
      MustInput7Character: 'Please input 7 half-width numbers without hyphens.',
      MinLength1Error: 'Please input more than 1 characters',
      MaxLength7Error: 'Please input less than 7 characters',
      MaxLength50Error: 'Please input less than 50 characters',
      DuplicatedEmailError: 'Email is duplicated',
      TheStaffServing: 'The staff serving ',
      IsChanged: ' is changed.',
      ValidateDiscountError: 'Discount must not be greater than total price!',
      WorkingDayWarning: 'Please select at least one working day!',
      BusinessHourWarning: 'Business Hour - End Time must be greater than Start Time!',
      TipsForStaffPlaceholder: 'Tips for staff',
      RefundReasonPlaceholder: 'Select refund reason!',
      Personal: 'Personal',
      Company: 'Company',
      'Business Hour': 'Business Hour',
      SettingBusinessHours: ' Setting Business Hour',
      'Close dates': 'Close dates',
      'Add Japan public holidays': 'Add Japan public holidays',
      'Add new closed date': 'Add new closed date',
      Salon: 'Salon',
      Account: 'Account',
      AddServicesForStaffConfirmContent: 'The new staff services list will be apply for the Target system that you are connected. Are you sure?',
      'One day': 'One day',
      'Several days': 'Several days',
      'Select year': 'Select year',
      CompleteBusinessInfo: 'Complete your business info',
      ManageSalonAccount: 'Manage your Salon account.',
      SystemInvitationDescription: 'Enter the Target system info to send the Salon invitation',
      'Select a system': 'Select a system',
      'System key': 'System key',
      'Sent invitation': 'Sent invitation',
      NailistUsernameNotFound: 'Nailist does not available to invite.',
      Connected: 'Connected',
      WaitingForConfirm: 'Waiting for confirm',
      Rejected: 'Rejected',
      Expired: 'Expired',
      Canceled: 'Canceled',
      CancelInvitation: ' Cancel invitation',
      ResendInvitation: 'Resend invitation',
      Resend: 'Resend',
      ConfirmResend: 'Do you want to resend?',
      Disconnect: 'Disconnect',
      UnderConstruction: 'Under Construction!',
      ConfirmCancel: 'Do you want to cancel?',
      SessionExpired: 'Session expired. Please login again.',
      WorkingTime: 'Working time',
      EditWorkingTime: 'Edit working time',
      UpdateWorkingTime: 'Update Working time',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      Sunday: 'Sunday',
      TwentyFourHours: '24 hours',
      EndTime: 'End time',
      StartTime: 'Start time',
      EditImage: 'Edit Image',
      InvalidAccountNumber: 'Account number is 7 digits. If the account number is less than 7 digits please put a 0 at the beginning',
      InvalidAccountName: 'Account Name should be Katakana',
      ImageForBankProof: 'Image for bank proof',
      Normal: 'Normal',
      Saving: 'Saving',
      'Edit Schedule': 'Edit Schedule',
      OpenSlot: 'Do you want to open this slot?',
      OpenSlots: 'Do you want to open these slots?',
      CloseSlot: 'Do you want to close this slot?',
      CloseSlots: 'Do you want to close these slots?',
      Week: 'Week',
      Day: 'Day',
      Hour: 'hour(s)',
      Hours: 'h',
      Minutes: 'm',
      Seconds: 's',
      'Save and apply for Staff': 'Save and apply for Staff',
      OutsideBookingsModalTitleHead: 'Bookings out of ',
      OutsideBookingsModalTitleTail: ' working time',
      OutsideBookingsModalContent: 'The schedule has been updated.\nThere are some bookings outside of your new working hours. Please check carefully.',
      'Menu Detail': 'Menu Detail',
      MenuNewText: 'New user',
      MenuRepeaterText: 'Repeater',
      MenuAllUserText: 'All user',
      MenuNewEditText: 'New user',
      MenuRepeaterEditText: 'Repeater',
      MenuAllUserEditText: 'All user',
      MenuTitle: 'Menu',
      DetailBtn: 'Detail',
      MenuStatusAssigned: 'Assigned',
      MenuStatusNotAssigned: 'Not assigned',
      BookingAvailabilityOK: 'OK',
      BookingAvailabilityNO: 'Unpublished',
      BookingAvailabilityTitle: 'Publication Status',
      'Salon Manager': 'Salon manager',
      ModalInforTitle: 'Have you completed posting nail design (in app) and setting menu?',
      ModalInforContent: '*It may take up to 5 minutes to update the status “OK”',
      Optional: 'optional',
      MinPriceExtraPromptMessageHead: 'Not accepted under ¥',
      MinPriceExtraPromptMessageTail: '',
      MaxPriceExtraPromptMessageHead: 'Not accepted above ¥',
      MaxPriceExtraPromptMessageTail: '',
      MenuDetailPlaceholder: 'Enter the menu details',
      'Menu Image': 'Menu Image',
      Disable: 'Disable',
      Disabled: 'Disabled',
      Enable: 'Enable',
      UpdateServiceStatusModalContentHead: 'Are you sure to ',
      UpdateServiceStatusModalContentTail: ' the service?',
      PhotoExtraPromptMessage: 'You can upload image under 5M',
      EmptyStateServicesMessage: 'There is no menu in this category. Click the "Add menu" button to create one.',
      RecommendedService: 'Recommended service',
      SaveAndPublishServices: 'Save and publish',
      Recommended: 'Recommended',
      RecommendedPlaceholder: 'Please select a recommended service',
      // cancelBooking
      MessageToCustomerPlaceholder: 'Example: I am sorry.A fire broke out in the nail salon and it disappeared, making the procedure difficult.',
      MessageToOperatorPlaceholder: 'Example: A fire broke out in the nail salon and it disappeared, making the procedure difficult.',
      EmptyCancelReasonError: 'Please specify your reason to cancel',
      EmptyCancelMessageError: 'Please enter a message for the customer',
      EmptyCancelMessageOperatorError: 'Please enter a message for the management office',
      CancellationType: 'Cancellation type',
      CancellationDescription: 'Specify your cancellation reason by selecting one option below',
      CustomerCancel: 'Customer cancel',
      CustomerCancelContent: 'Customer no visit after treatment time.',
      NailistCancel: 'Nailist cancel',
      NailistCancelContent: 'If you cancel approved reservation, the evaluation will be lowered automatically.',
      EmergencyCanCel: 'Emergencies (natural disasters, train/bus delays or cancellations)',
      EmergencyCancelContent: 'In case of cancellation due to an emergency situation, we may ask you to inform us of circumstances from Nailie and submit necessary documents.',
      MessageToCustomer: 'Message to customer',
      MessageWithEmergencyReason: 'Message to Nailie Operator',
      CancellationCharge: 'Cancellation Charge',
      CancellationChargeTitle: 'Cancellation due to customer\'s convenience',
      CancellationChargeContentHead: '【Caution】If you cancel for your reason, you will be charged  ',
      CancellationChargeContentMiddle: '',
      CancellationChargeContentTail: ' cancellation fee.',
      CancellationChargeContentNofee: 'No cancellation fee will be charged',
      NailistSalesPrice: 'Nailist sales price',
      Send: 'Send',
      StaffWorkingTimeViewModalContentHead: 'Staff schedule apply from ',
      StaffWorkingTimeViewModalContentTail: ' to present',
      StaffWorkingTimeFooter: 'This will take effect for the Staff schedule from tomorrow.',
      ViewHere: 'View here',
      OFF: 'OFF',
      LatestScheduleHead: 'The schedule below will be applied from ',
      LatestScheduleTail: '. ',
      ClickHereEnglish: 'Click here',
      ClickHereJapanese: '',
      PastScheduleHead: ' to view the staff schedule applied from ',
      PastScheduleTail: ' until present.',
      'Show Booking': 'Show Booking',
      SetRecommend: 'Set recommend',
      RemoveRecommend: 'Remove recommend',
      Other: 'Other',
      SurveyCoronaText: 'It is now possible to display the countermeasure status of coronavirus to customers on Nailist MyPage.Choose the measures you are taking in your salon.',
      SaveAndPublishSettings: 'Save and Publish Settings',
      'Additional price': 'Additional price',
      AcceptanceTimeTitle: 'Acceptance time',
      AcceptanceTimeContent: 'The are many customers make a reservation in the same day at Nailie. \n The number of bookings will increase dramatically just by accepting reservations on the day!',
      AcceptanceTimeLabel: 'Acceptance time',
      AcceptanceDateLabel: 'Acceptance date',
      'Salon sales report': 'Salon sales report',
      Period: 'Period',
      'Booking Done': 'Booking Done',
      'Customer canceled': 'Customer canceled',
      'Salon canceled': 'Salon canceled',
      'Total treatment fee': 'Total treatment fee',
      'Nailie fee': 'Nailie fee',
      'Transfer amount': 'Transfer amount',
      'Staff sales report': 'Staff sales report',
      'Booking data': 'Booking data',
      'First period': 'First period',
      'Second period': 'Second period',
      'Full month': 'Full month',
      'View more detail': 'View more detail',
      Sale: 'Sale',
      Earnings: 'Earnings',
      'Manage bookings': 'Manage bookings',
      'Search bookings': 'Search bookings',
      CompleteBookingInstruction: 'Due to the specifications of the payment system, the minimum payment amount for customers when using discounts is ¥100.',
      CompleteBookingSubtotalEmphasize: 'Price before discount will be changed to this amount',
      CompleteBookingMemoHelp: 'This is a memo to tell customer the reason for the price change',
      CompleteBookingMemoError: 'Please input memo',
      CompleteBookingSubtotalErrorHead: 'Price must be over ',
      CompleteBookingSubtotalErrorTail: 'Y',
      'Complete booking': 'Complete booking',
      Memo: 'Memo',
      InputMemoPlaceholder: 'Input memo',
      ChangePrice: 'Change price',
      EditPrice: 'Edit price',
      'Change date time': 'Change date time',
      ChangeBookingDateModalTitle: 'Change booking date and time',
      'New booking date': 'New booking date',
      ChangeDateReasonLabel: 'Message to customers',
      Required: 'required',
      Notes: 'Notes',
      ChangeBookingDateModalNotes: 'Bookings can be changed within 60 days from the date of request. Please refrain from changing your booking due to the convenience of your nail technician. If it is unavoidable, please make changes after obtaining the customer\'s consent in advance by sending a message.',
      'I checked notes': 'I checked the notes',
      Reload: 'Reload',
      Reset: 'Reset',
      'Transfer management': 'Transfer management',
      Details: 'Details',
      'Charged amount': 'Charged amount',
      'Total sales': 'Total sales',
      'Done amounts': 'Done amounts',
      'Canceled amounts': 'Canceled amounts',
      Average: 'Average',
      'Sales amount': 'Sales amount',
      'Sales management': 'Sales management',
      'Payout cycle': 'Payout cycle',
      'Booking fee': 'Booking fee',
      'Transfer fee': 'Transfer fee',
      'Transfer date': 'Transfer date',
      Fee: 'Fee',
      CountDoneNew: 'New',
      NewType: 'NEW',
      RepeatType: 'REPEATER',
      CountDoneRepeater: 'Repeater',
      BookingCustomerTypeTitle: 'New/Repeater',
      SubtotalSales: 'Subtotal',
      TransferAmountSales: 'Transfer amount',
      'Scheduled transfer': 'Scheduled',
      'Transfer done': 'Done',
      'Sales detail': 'Sales detail',
      Menu: 'Menu',
      Case: 'case',
      WaitingDoneBookingWarning: 'Please choose the customer and finish payment!\nPlease confirm the payment as soon as poosible.',
      WaitingDoneBookingWarningImportant: '* Sales will be lost and the evaluation of the nail technician will be lowered, so please make an early payment.',
      Employer: 'Person in charge',
      Deadline: 'Deadline',
      SalonIntegrationTitle: 'External cooperation',
      SalonConnect3rdPartiesScheduleDescription: 'Choose to connect to BeautyMerit or coming-soon ( you can not connect both at the same time)',
      SalonBMScheduleLabel: 'Sync BeautyMerit schedule',
      Allow: ' allow ',
      Prevent: ' prevent ',
      Connect: 'Connect',
      ConnectWithCSTitle: 'Connect with coming-soon',
      Token: 'Token',
      InputTokenCSPlaceholder: 'Input CS Token',
      InputTokenCSError: 'This resource requires a valid token',
      ConnectCSInputTokenDescription: "To automatically link Nailie and coming-soon, enter the authentication token issued by coming-soon. Must be.If you don't know about the authentication token, please contact coming-soon.",
      SalonCommon: 'Salon common',
      NailistOnly: 'Nailist only',
      EyelistOnly: 'Salon only',

      BMContentHead: 'Are you sure ',
      BMContentTail: 'sync BeautyMerit schedule?',
      BMDisConnectAlert: 'Please disconnect with BeautyMerit first!',
      CSDisConnectAlert: 'Please disconnect with coming-soon first!',
      BMContentDisconnect1: 'We will unlink BeautyMerit cooperation, are you sure?',
      BMContentDisconnect2: '',
      BMContentConnect: 'Do you want to start schedule collaboration with BeautyMerit?',
      CSContentDisconnect1: 'Do you want to end the collaboration with coming-soon？',
      CSContentDisconnect2: '',
      CSContentConnect: 'Do you want to start schedule collaboration with coming-soon?',
      CSContentHead: 'Are you sure ',
      CSContentTail: 'sync coming-soon schedule?',
      BeautyMeritTitle: 'BeautyMerit',
      ComingSoonTittle: 'coming-soon',
      ExternalParties: 'External parties',
      'Send the update': 'Send the update',
      'Confirmation Popup': 'Are you sure updating the date?',
      AppliedNailistScheduleHead: 'Applied for',
      AppliedNailistScheduleTail: 'nailist.',
      ChangeBookingDateSuccess: 'Booking date and time has been changed.',
      NavigationAccess: 'Navigation-Access',
      WalkingTime: 'Walking time',
      NearestStation: 'The nearest Station',
      NearestStationExtraInfo1: '※Even if you do not set the nearest station, it will be automatically detected within a radius of 1.5 km, so it will be displayed in the search results.',
      NearestStationExtraInfo2: '※If there is no nearest station, leave it blank.',
      MinutesWalkingTime: 'minutes',
      UpdateStationPopupTitle: 'The nearest station.You can set it now!',
      UpdatePropertiesPopupTitle: 'New salon feature properties items are added. Now you can choose!',
      UpdatePropertiesPopupContent: 'Click on these items in Account Setting > Salon Properties to apply your settings.',
      UpdateStationPopupContent: 'When you set the nearest station. Easier to search with Nailist Search. Set it up now!',
      UpdateStationPopupOkText: 'Set up immediately',
      UpdateStationPopupCancelText: 'Later',
      StaffInfo: 'Staff info',
      StaffInforSearchPlaceholder: 'Search by phone number or email address',
      ConfirmArriveOnTime: 'Did customer come on time?',
      AdditionalInfor: 'Notes',
      NailieBookedNote: 'Nailie Booked',
      DelayedNote: 'Delayed',
      CanceledNote: 'Canceled',
      Times: 'times',
      Retry: 'Retry',
      CSConnectStaffTitle: 'Select staff will sync schedule with coming-soon',
      CsConnectStaffWarningHead: 'It may take some time to get the tough information.',
      CsConnectStaffWarningTail: 'If it takes a long time, you can reload it.',
      SalonStaff: 'Salon staff',
      ComingSoonStaff: 'coming-soon staff',
      SelectStaffPlaceholder: 'Select Staff',
      DisconnectThirdParty: "Don't connect",
      ConnectedWithCS: 'Connect with coming-soon',
      ProcedureLinkCS: 'Procedure to link coming-soon',
      ConnectCSstep1: '① Please operate the start of cooperation with nails with coming-soon.',
      ConnectCSstep: 'Steps:',
      ConnectCSstepLine1: 'Display a Nailie page from "External cooperation" on coming-soon.',
      ConnectCSstepLine2: 'After confirming that it is set to "reflect the booking information", enter the execution password and "issue an authentication token".',
      ConnectCSstep2: '② Turn on  "Start coming-soon cooperation" on Nailie Salon Manager',
      ConnectCSstepLine3: 'Start coming-soon cooperation',
      ConnectCSstepLine4: 'Enter the authentication token issued by coming-soon',
      ConnectCSstepLine5: 'Link coming-soon and nailed staff.',
      ConnectCSstepNote: '【Notes】',
      ConnectCSstepNoteContent: 'Staff synchronization takes about 15-20 minutes per person',
      ConnectedWithBM: 'Connect with BeautyMerit',
      DisconnectWithBMBtn: 'Disconnect with BeautyMerit',
      DisconnectWithCSBtn: 'Disconnect with coming-soon',
      HowToConnectBM: 'How to connect BeautyMerit',
      TurnOnSwitchButton: 'Turn on the switch button below.',
      BeautyMeritDescription1: " ※The schedule of the third 's party will be prioritized when you connect with BeautyMerit",
      FollowProgressSetting: 'Follow the progress on integration setting on BeautyMerit',
      BMConnectSteps: 'Steps',
      BMStepLine1: 'Go to 「Option」⇒「Management Settings」',
      BMStepLine2: 'Input Login ID and Password of Nailie Salon Manager',
      BMStepLine3: 'Staff Mapping between with the staffs of BeautyMerit and Nailie',
      BMNoteLine1: 'Please contact BeautyMerit to synchronize your schedule after you have completed the ',
      BMNoteLine2: 'linkage operation on the BeautyMerit side.The schedule synchronization will take about',
      BMNoteLine3: '15-20 minutes per staff member.',
      BMNoteLine4: 'If you add a staff member during the integration progress, please be sure to contact your',
      BMNoteLine5: 'BeautyMerit contact to synchronize the schedule for that staff member.',
      WarningDisconnectOther3rdPartyHead: 'You are connected to another external system now.',
      WarningDisconnectOther3rdPartyTail: 'Please disconnect the current connection first.',
      WarningDisconnectOther3rdPartyTitle: 'Warning',
      ScheduleWarningWhenConnected3rd: "This should not be used as a regular schedules tool. Schedule should still be managed on the external party's website.",
      Back: 'Back',
      EmergencyCanCelTitle: 'Emergency cancel',
      EmergencyCanCelDescription: 'Do the cancellation for following reasons',
      EmergencyReasonExample: '「 Emergencies (natural disasters, train/bus delays or cancellations)」',
      EmergencyAttentionNote: '【ATTENTION】In case of cancellation due to an emergency situation, we may ask yoi to inform us of circumstances from Nailie and submit necessary documents.',
      EmergencyConfirmSelect: 'Confirmed the details of the emergency',
      MessageToCustomerNote: '※ This content will be sent as a message to the customer',
      MessageToOurCustomerConfirmed: 'Message to our customer',
      MessageToAdminOperatorConfirmed: 'Message to Nailie Operator',
      MessageToNaileOperator: 'Message to Nailie Operator',
      MessageToOperatorNote: '※ This content will be confirmed by the management office.',
      EmptyEmergencyPolicy: 'Please tick the emergency policy.',
      EmergencyCancelConfirmTitle: 'We will cancel your reservation due to an emergency (natural disaster, train/bus delays or cancellations) Are you sure?',

      NailistCanCelTitle: 'Nailist cancel',
      NailistCanCelDescription: '「サロン（施術者）都合によるキャンセル」を行います',
      NailistAttentionNote: '【ATTENTION】In case of cancellation on the day due to nailist convenience, it will display on your review screen that Nailist Cancel occurred.And also it will be reflected to your evaluation.',
      ReviewImage: 'レビュー表示イメージ',
      AutomaticPostingTitle: '【ネイリー運営事務局の自動投稿】',
      AutomaticPostingContent: '○月○日○○：○○-の予約が、ネイリストの都合によりキャンセルされました。',
      NailistCancelConfirmContent: 'If you cancel, your evaluation will go down automatically. Are you sure you want to cancel?',
      WarningCompleteMapping: 'The initial setting will be done when you finish user-mapping',
      MappingPopupTitle: 'Employee mapping',
      ContentDisconnect3rdParty: "Cancel the reservation cooperation with the staff's coming- soon.Is it OK? ",
      ContentDisconnect3rdPartyHead: 'If the cooperation is canceled, the schedule after the reception start date (',
      ContentDisconnect3rdPartyTail: 'days）will be "○" for the target staff based on the business hours setting.',
      Unlink: 'unlink',
      BookingConfirmedDate: 'Confirmed Date',
      ChangeEmailInput: 'New e-mail address',
      SettlementFee: 'Settlement fee',
      ChangeEmailAddress: 'Change email address',
      ChangeSalonEmailModalTitle: 'Change email address process',
      ChangeEmailStep1: 'Send a verification mail to a new address',
      ChangeEmailStep2: '.Click a mail link',
      ChangeEmailStep3: 'Update is completed',
      ChangeSalonEmail: 'Update e-mail address from this',
      ChangeEmailWarning: '※If you update an e-mail address, the e-mail address for login will be updated to new email address automatically',
      ChangedEmailAlertContent1: 'We sent a mail for verification,please complete the verification by clicking URL',
      ChangedEmailAlertContent2: '',
    },
  },
  ja: {
    translation: {
      Welcome: 'こんにちは',
      'My Salon': 'マイサロン',
      Bookings: '予約管理',
      Payment: '売上管理',
      Staff: 'スタッフ',
      Report: '報告書',
      Profile: 'サロン情報',
      Schedule: '営業時間',
      CancellationPolicy: 'キャンセルポリシー',
      CancellationPolicyNote1: 'キャンセルポリシーは、お客様が予約時に事前に確認しているため、お客様が予約リクエストを送信された時点でのキャンセルポリシーが適用となります。',
      CancellationPolicyNote2: '予約確定後にポリシーを変更してもそちらは適用されませんのでご注意ください。',
      'Change Password': 'パスワードを変更する',
      'Account settings': '基本設定',
      'Salon Account': 'サロンアカウント',
      Logout: 'ログアウト',
      Services: '対応メニュー',
      MenuSettings: 'メニュー管理',
      Customers: 'カスタマー',
      Settings: '設定',
      'Staff Schedule': '個別スケジュール',
      'Publish Schedule': '公開スケジュール', //
      'Staff Member': 'スタッフ管理',
      'Staff Overview': 'スタッフ概要',
      'Staff name': 'スタッフ名',
      Name: '名前',
      FullName: '姓名',
      Email: 'メールアドレス',
      'Email address': 'メールアドレス',
      Phone: '電話番号',
      Avatar: 'プロフィール画像',
      Phonetic: 'ふりがな',
      PersonalPhoneNumber: '個人の電話番号',
      Status: 'ステータス',
      Gender: '性別',
      Male: '男性',
      Female: '女性',
      Prefectures: '都道府県',
      'Post Code': '郵便番号',
      City: '市',
      Area: 'エリア',
      Address: '番地',
      'Building name': '建物名等',
      'room number': '部屋番号',
      'City or Town': '市区町村',
      'Address mode': 'サロン住所公開設定',
      'Always show salon address': 'サロンの住所を常に表示',
      'Show salon address only to the confirmed customer': '予約が確定したお客様にのみ公開',
      'Select an option that you want to public salon address or not': 'サロン住所はお客様に公開するかしないかを選んでください',
      'Salon area': 'サロンエリア',
      'Select Salon Area': 'サロンエリアを選択する',
      Region: '地域',
      Prefecture: '都道府県',
      'Business Type': '営業のタイプ',
      'Business name': 'サロン名',
      Logo: 'ロゴ',
      Change: '編集',
      All: 'すべて',
      Nail: 'ネイル',
      Eye: 'アイ',
      Hair: 'ヘア',
      Massage: 'マッサージ',
      Spa: 'スパ',
      BasicInformation: 'サロン情報',
      SalonProperties: 'サロンの特徴',
      SalonDescription: 'サロンについて・注意事項',
      SalonDescriptionPlaceholder: 'サロンの情報をここに入力してください',
      SalonDescriptionExtra: '※LINEや電話番号など連絡先の掲載やSNS、他のサービスへの誘導はお控えください',
      SalonImages: 'サロンイメージ写真',
      SalonLogoExtraUpload: '1枚あたり5MB以下、',
      SalonImagesExtraUpload: '1枚あたり5MB以下、最大3枚までの画像をアップロード可能です',
      'Update and Publish': '保存して公開する',
      Continue: '次へ',
      'Continue to complete your Salon schedule': 'サロン情報を入力してください',
      'Continue to complete your Salon settings to start your business': 'あなたのビジネスを始めるためにサロンのスケジュールを設定してください',
      SearchStaffPlaceholder: 'スタッフ名・メール・電話番号で検索',
      SearchBookingPlaceholder: '予約番号・カスタマー・スタッフで検索',
      SearchPaymentPlaceholder: '支払いID・予約番号・カスタマー名・カスタマーお支払額で検索',
      SearchStaffOverviewPlaceholder: '予約番号・カスタマー・ネイリストで検索',
      SearchStaffSaleReportPlaceholder: 'スタッフを探索',
      'From date': '０日から',
      'To date': '０日まで',
      Password: 'パスワード',
      'Message (optional)': 'メッセージ（任意）',
      Birthday: '誕生日',
      'Add Category': 'カテゴリを追加',
      'Add Service': 'サービスを追加',
      'Add Menu': 'メニューを追加',
      'Add date': '日付を追加',
      'Add Staff': 'スタッフを追加',
      'Add Schedule': 'スケジュールを追加',
      'Add Discount': 'ディスカウントを追加',
      'Add Customer': 'カスタマーを追加',
      'Update Category': 'カテゴリを編集',
      'Update Service': 'サービスを編集',
      'Update Menu': 'メニューを編集',
      'Create Booking': '予約作成',
      'Edit Banking': '銀行情報を編集',
      'Add Tips': 'チップを追加',
      'Remove tips': 'チップを削除',
      'Tip for': 'にチップ',
      'Tip for all staff': '全スタッフにチップ',
      'Cancel Booking': '予約をキャンセル',
      UnableCompleteTreatment: '施術ができなかった場合はこちら',
      'Update Booking': '予約を変更',
      'Edit Customer': 'カスタマー情報を編集',
      'Update Customer': 'カスタマー情報を変更',
      'Edit Account': 'アカウントを編集',
      'Edit Account Info': 'アカウント情報を編集',
      'Update Bank Account Info': '銀行口座情報を編集',
      'Edit Bank Info': '銀行情報を編集',
      Active: 'アクティブ',
      Inactive: '非活動',
      'Personal Info': 'プロフィール',
      'Banking Account': '銀行口座',
      Branch: 'ブランチ',
      'Bank name': '銀行名',
      'Branch name': '支店名',
      'Account type': '口座種別',
      'Account number': '口座番号',
      'Account name': '口座名義',
      BankbookTitle: '法人確認のため通帳の登録をお願いいたします。',
      BankbookDescription1: '通帳の１ページ目を開いて準備してください。',
      BankbookDescription2: '口座種別・口座名義・店番号・口座番号・金融機関名・支店名の書かれているページです。',
      'Category Name': 'カテゴリ名',
      'Category Description': 'カテゴリ説明',
      'Payment report': '支払いレポート',
      'Billing count': '請求数',
      'Online payment': 'オンライン決済',
      'Total amount': '合計',
      'Menu Type': 'メニュー区分',
      'Menu Category': 'メニューカテゴリー',
      'Menu Name': 'メニュー名',
      Price: '価格',
      IncludedTax: '税込',
      PriceIncludedTax: '価格（税込）',
      Description: '説明',
      Duration: '所要時間',
      min: '分',
      Tips: 'チップ',
      'All staff': '全スタッフ',
      Billing: '請求',
      Cash: '現金',
      Card: 'カード',
      'Customer ID': 'カスタマーID',
      Date: '日付',
      'Customer unsatisfied': 'カスタマーが不満',
      'Bad design': '悪いデザイン',
      'Bad services': '悪いサービス',
      'Cancellation fee': 'キャンセル料',
      'Booking Detail': '予約内容',
      'Booking ID': '予約番号',
      'Treatment date': '施術日',
      'Customer Detail': 'カスタマー情報',
      'Create Date': '作成日',
      'Total Purchase': '予約した金額',
      Ranking: 'ランキング',
      'Last Visit Date': '最終訪問日',
      'Customer name': 'カスタマー名',
      'Total fee': '合計金額',
      System: 'システム',
      'Status update': 'ステータス変更',
      'Payment ID': '支払いID',
      'Created date': '作成日',
      'Payment method': '支払方法',
      'Target system': 'ターゲットシステム',
      'Customer pay': 'お客様お支払額',
      RankingBronze: 'ブロンズランク',
      RankingSilver: 'シルバーランク',
      RankingGold: 'ゴールドランク',
      RankingPlatinum: 'プラチナランク',
      RankingDiamond: 'ダイヤモンドランク',
      Estimate: '予定',
      'Payment Detail': '支払い内容',
      'Payment Info': '支払い情報',
      'Customer Info': 'お客様情報',
      'Cash payment': '現金決済',
      Subtotal: '予約時の金額',
      'Total due': '決済金額',
      UseCoupon: 'クーポンコード割引',
      UsePoint: 'ポイント利用',
      Tax: '税金',
      Total: 'ネイリスト売上',
      'Booking Date': '施術日',
      BookingTime: '時間',
      Customer: 'カスタマー',
      'New Customer': 'カスタマーを追加',
      'Start Time': '開始時間',
      'Quick Payment': 'お会計をしてください',
      'Discount Cash': 'ディスカウント',
      'Process payment': '支払いの処理',
      'Refund reason': '返済の理由',
      Reason: '理由',
      Note: 'メモ',
      'Staff Profile': 'スタッフのプロフィール',
      'Upload Photo': '写真を投稿',
      'Edit Profile': 'プロフィールを編集',
      RemoveStaff: 'スタッフを消除',
      Rating: '評価',
      Timesheet: 'スケジュール',
      Sales: '売上',
      Review: 'レビュー',
      'Banking Information': '口座情報',
      'Bank Name': '銀行名',
      'Bank branch': '支店名',
      Complete: '完了',
      Import: 'インポート',
      'Import from Nailie': 'スタッフを招待する',
      'Nailie username': 'ネイリーユーザーネーム',
      UserName: 'ユーザーネーム',
      CheckStaffInvitationErrorTitle: 'スタッフが存在しました',
      CheckStaffInvitationErrorContent: '別のスタッフをインポートしてください!',
      EmailSettingDescription: '予約の変更（予約確定、予約キャンセルなど）および口座情報については、以下のメールに送信します。',
      'Label name': 'ラベル名',
      'Default Label': 'デフォルト',
      Add: ' 追加 ',
      Delete: '削除',
      Edit: '編集',
      Search: '探す',
      Export: 'エクスポート',
      ExportCSV: 'CSVをエクスポート',
      Home: 'ホーム',
      'Search Result': '検索の結果',
      'Waiting for payment': 'お会計待ち',
      Success: '成功',
      Error: 'エラー',
      'Bank tranfer': '銀行振込',
      New: '新しい',
      Arrived: '到着',
      Started: 'スタート',
      Done: '完了',
      ReservationAlertBtn: '予約アラート',
      ReservationAlertTitle: 'アラート情報',
      ReservationAlertMessage: 'あなたは今すべての通知を見ました',
      NotificationMessageConfirm: '予約が確定しました。',
      NotificationMessageExpired: 'お会計期限切れ。事務局キャンセルとなりました。',
      NotificationMessageCanceled: '予約キャンセルされました。',
      NotificationMessageNovisit: 'ご予約の時間が過ぎましたがご来店が確認できなかったため当日キャンセルとさせて頂きました。',
      TitleStatusWaitingDone: 'お会計待ち',
      StatusConfirmed: '確定',
      StatusDone: '施術完了',
      StatusWaitingDone: 'お会計待ち',
      StatusDeclinedReservation: '(N) 予約キャンセル',
      StatusNailistCanceled: 'ネイリストキャンセル',
      StatusCardErrorCancel: 'カードエラー自動キャンセル',
      StatusCanceledReservation: '(C) 予約キャンセル',
      StatusCanceled: 'キャンセル',
      StatusNoVisit: '不来店',
      StatusUndone: '緊急事態キャンセル',
      StatusNailieCanceled: 'お会計期限切れ',
      StatusDeleted: 'DELETED',
      PaymentStatusWaiting: 'お会計待ち',
      PaymentStatusSucceeded: '支払い完了',
      PaymentStatusError: '決済エラー',
      PaymentStatusCanceled: 'キャンセル',
      PaymentStatusRefunded: '返済',
      PaymentMethodOnline: 'オンライン決済',
      PaymentMethodAtone: 'Atone',
      PaymentMethodBankTransfer: '銀行振込',
      PaymentSummary: 'お会計',
      Cancel: 'キャンセル',
      Save: ' 保存 ',
      Refund: '返済',
      Confirm: '確認',
      Action: 'アクション',
      Copy: 'コピー',
      No: 'いいえ',
      Yes: 'はい',
      Sure: 'はい',
      Select: '選択してください',
      SelectedOption: '選択する',
      EditPlaceOfPin: 'ピンの場所を編集する',
      MapSettings: 'マップ設定',
      MapSettingsExtra: 'マップをクリックするとピンの位置を変更できます',
      'No fee': '無料',
      'Charge 50% booking fee': 'キャンセル料50%',
      'Charge 100% booking fee': 'キャンセル料100%',
      Loading: 'ローディング中',
      'Log in': 'ログイン',
      'Request account': 'アカウントをリクエストする',
      'Back to login': 'ログインに戻る',
      'Already have an account?': 'すでにアカウントを待ちますか?',
      RequestAccountSalonSuccessMessage: '確認メールを今すごく送信します./n/nリクエストの承認をお待ちください.',
      HeadTermsOfServices: '',
      TermsOfServices: '利用規約',
      TailTermsOfServices: 'に同意します',
      ConfirmDeleteCategory: 'カテゴリを削除してもよろしいですか？',
      ConfirmDeleteService: 'メニューを削除してもよろしいですか？',
      ConfirmCompleteBooking: '️この予約のお会計をします。よろしいですか？',
      ConfirmCancelBooking: '予約をキャンセルしてもよろしいですか？',
      ConfirmCancelInformation: 'この情報を保存しなくてもよろしいですか？',
      ConfirmCancelBilling: 'この請求をキャンセルしてもよろしいですか？',
      ConfirmLogout: '本当にログアウトしますか？',
      ConfirmDeleteStaffTitle: 'スタッフを削除してもよろしいですか？',
      ConfirmDeleteStaffContent: 'ネイリーのアカウントの連携が停止しましたが、ネイリーアプリでまだ使用できます',
      ConfirmDeleteEmail: 'このメールを削除してもよろしいですか?',
      'Change password process': 'パスワード変更プロセス',
      'Receive email': 'メールを受信。',
      'Click on the email link': 'メールリンクをクリックする。',
      'Enter new password': '新しいパスワードを入力する。',
      EmptyCancellationFeeError: 'キャンセル料を選択してください！',
      EmptyBookingDateError: '日付を選択してください！',
      EmptyStatusError: 'ステータスを選択してください！',
      EmptyStartTimeError: '開始時間を選択してください！',
      EmptyEndTimeError: '終了時間を選択してください！',
      EmptyServiceError: 'サービスがない！',
      EmptyStaffError: 'スタッフがない！',
      EmptyStaffProfilePicture: 'プロフィール写真を登録',
      EmptyStaffNameError: 'スタッフ名を入力してください！',
      EmptyUserNameError: 'ユーザーネームを入力してください!',
      EmptyCustomerError: 'カスタマーを選択してください！',
      EmptyNameError: 'カテゴリ名を入力してください！',
      EmptyCustomerNameError: 'カスタマー名を入力してください！',
      EmptyPhoneNumberError: '電話番号を入力してください！',
      EmptyPhoneticError: 'ひらがなで入力してください',
      EmptyRefundReasonError: '返済の理由を選択してください！',
      EmptyBusinessNameError: 'サロン名を入力してください',
      EmptyPostCodeError: '郵便番号を入力してください！',
      EmptyBuildingRoomError: '建物名/部屋番号等を入力してください!',
      EmptySalonAreaError: 'サロンエリアを選択してください!',
      EmptyAddressModeError: 'サロン住所公開を設定してください！',
      EmptyAddressTownError: '市区町村を入力してください！',
      EmptyAddressError: '住所を入力してください！',
      EmptyEmailError: 'メールアドレスを入力してください！',
      EmptyBankNameError: '銀行名を選択してください！',
      EmptyBranchNameError: '支店名を選択してください！',
      EmptyAccountTypeError: '口座種別を選択してください！',
      EmptyAccountNumberError: '口座番号を入力してください！',
      EmptyAccountNameError: '口座名義を入力してください！',
      EmptyImageForBankProofError: '通帳の写真入力してください！',
      EmptyServiceNameError: 'メニュー名を入力してください',
      EmptyServiceCategoryError: 'サービスカテゴリを選択してください！',
      EmptyDurationError: '所要時間を選択してください！',
      EmptyPriceError: '価格を入力してください！',
      EmptyNailieUsernameError: 'ネイリストの電話番号またはメールアドレスを入力してください',
      EmptySystemError: 'システムを入力してください！',
      EmptySystemKeyError: 'システムのキーを入力してください！',
      EmptyBusinessHourStartTimeError: '開始時間を選択してください!',
      EmptyBusinessHoureEndTimeError: '終了時間を選択してください!',
      InvalidEmailError: 'メールアドレスは無効です',
      InvalidPhoneNumberError: '電話番号は無効です！',
      MinLengthPhoneNumberError: '半角数字ハイフンなし、10桁または11桁で入力してください',
      MinBirthdayError: '年齢は18歳以上です!',
      MustInput7Character: '半角数字7桁ハイフンなしで入力してください.',
      MinLength1Error: '1文字以上で入力してください',
      MaxLength7Error: '７文字以上で入力してください',
      MaxLength50Error: '50文字以上で入力してください',
      DuplicatedEmailError: 'メールアドレスは存在しました',
      TheStaffServing: 'このサービスのスタッフ',
      IsChanged: 'が変更しました.',
      ValidateDiscountError: '割引は合計金額を超えてはなりません!',
      WorkingDayWarning: '少なくとも1営業日を選択してください！',
      BusinessHourWarning: '終了時間が開始時間より前です。',
      TipsForStaffPlaceholder: 'スタッフにチップ',
      RefundReasonPlaceholder: '返済の理由を選択してください！',
      Personal: '個人',
      Company: '法人',
      'Business Hour': '営業時間',
      SettingBusinessHours: '営業時間の設定',
      'Close dates': '閉店日',
      'Add Japan public holidays': '日本の祝日を追加',
      'Add new closed date': '閉店日を追加',
      Salon: 'サロン',
      Account: 'アカウント',
      AddServicesForStaffConfirmContent: ' こちらのメニューは連携しているネイリストに適応されます。よろしいですか？',
      'One day': '一日',
      'Several days': '数日',
      'Select year': '年数を選択してください',
      CompleteBusinessInfo: 'サロン情報の設定',
      ManageSalonAccount: 'サロンのアカウントを設定.',
      SystemInvitationDescription: 'ターゲットシステムの情報を入力してください',
      'Select a system': 'システムを選択',
      'System key': 'システムキー',
      'Sent invitation': 'インビテーションを送信した',
      NailistUsernameNotFound: 'ネイリストを存在しない。',
      Connected: '連携中',
      Disconnected: '連携停止中',
      Inviting: '招待中',
      WaitingForConfirm: 'ネイリストの参加を待っています',
      Rejected: '断りました',
      Expired: '招待が無効になりました',
      Canceled: 'キャンセルしました',
      CancelInvitation: '招待をキャンセル',
      ResendInvitation: '招待を再送信',
      Resend: '再送信',
      ConfirmResend: '再送信しますか？',
      Disconnect: 'サロンマネジャーからスタッフ連携を外す',
      UnderConstruction: '建設中！',
      ConfirmCancel: 'キャンセルしてもよろしいですか？',
      SessionExpired: 'セッションの有効期限が切れました。またログインしてください。',
      WorkingTime: '営業時間',
      EditWorkingTime: '営業時間を編集',
      UpdateWorkingTime: '営業時間を編集',
      Monday: '月曜日',
      Tuesday: '火曜日',
      Wednesday: '水曜日',
      Thursday: '木曜日',
      Friday: '金曜日',
      Saturday: '土曜日',
      Sunday: '日曜日',
      TwentyFourHours: '一日中',
      EndTime: '開始時間',
      StartTime: '終了時間',
      EditImage: '画像編集',
      InvalidAccountNumber: '口座番号は７ケタ入力してください. ※口座番号が7桁未満の場合は先頭に0をつけてください',
      InvalidAccountName: 'カタカナ又は()-の文字以外は使用できません',
      ImageForBankProof: '通帳の写真',
      Normal: '普通',
      Saving: '当座',
      'Edit Schedule': 'スケジュールを編集',
      OpenSlot: 'このスロットを開けますか？',
      OpenSlots: '全部を開けますか？',
      CloseSlot: 'このスロットを閉めますか？',
      CloseSlots: '全部を閉めますか？',
      Week: '週',
      Day: '日',
      Hour: '時間',
      Hours: '時',
      Minutes: '分',
      Seconds: '秒',
      'Save and apply for Staff': '保存してスタッフにアプライします',
      OutsideBookingsModalTitleHead: '',
      OutsideBookingsModalTitleTail: '営業時間以外の予約です',
      OutsideBookingsModalContent: 'スケジュールが変更しました。\nスタッフの営業時間以外に予約があります。もう一回確認してください。',
      'Menu Detail': 'メニュー説明',
      MenuNewText: '新規',
      MenuRepeaterText: '再来',
      MenuAllUserText: '全員',
      MenuNewEditText: '新規限定',
      MenuRepeaterEditText: '再来限定',
      MenuAllUserEditText: '全員OK',
      ServiceRoleTitle: '対応ジャンル',
      MenuTitle: '対応メニュー',
      DetailBtn: ' 詳細 ',
      MenuStatusAssigned: '設定済',
      MenuStatusNotAssigned: '未設定',
      BookingAvailabilityOK: '掲載OK',
      BookingAvailabilityNO: '掲載NG',
      BookingAvailabilityTitle: '掲載',
      'Salon Manager': 'サロンマネージャー',
      ModalInforTitle: 'デザイン投稿(アプリ)・対応メニュー設定はお済みでしょうか?',
      ModalInforContent: '※上記対応していただくと、5分程で掲載OKに変わります',
      Optional: '任意',
      MinPriceExtraPromptMessageHead: '',
      MinPriceExtraPromptMessageTail: '円以下は入力できません',
      MaxPriceExtraPromptMessageHead: '',
      MaxPriceExtraPromptMessageTail: '円以上は入力できません',
      MenuDetailPlaceholder: 'メニュー説明を入力してください',
      'Menu Image': 'メニュー画像',
      Disable: '非公開',
      Disabled: '非公開',
      Enable: '公開',
      UpdateServiceStatusModalContentHead: '',
      UpdateServiceStatusModalContentTail: 'してもよろしいですか？',
      PhotoExtraPromptMessage: '1枚あたり5MB以下',
      EmptyStateServicesMessage: '「メニューを追加」ボタンをクリックしてメニューを追加してください',
      RecommendedService: 'おすすめサービス',
      SaveAndPublishServices: '保存して公開します',
      Recommended: 'おすすめ',
      RecommendedPlaceholder: 'おすすめのメニューを選択してください',
      // cancelBooking
      MessageToCustomerPlaceholder: '例）申し訳ごさいません。ネイルサロン内で火災が発生して消失してしまたため施術が困難となりました。',
      MessageToOperatorPlaceholder: '例）ネイルサロン内で火災が発生して消失してしまたため施術が困難となりました。',
      EmptyCancelReasonError: 'キャンセル理由を選択してください',
      EmptyCancelMessageError: 'お客様へのメッセージを入力してください',
      EmptyCancelMessageOperatorError: '管理事務所へのメッセージを入力してください',
      CancellationType: 'キャンセルタイプ',
      CancellationDescription: 'キャンセル理由を選択してください',
      CustomerCancel: 'お客様都合によるキャンセル',
      CustomerCancelContent: '施術時間にお客様が来ない場合',
      NailistCancel: 'サロン（施術者）都合によるキャンセル',
      NailistCancelContent: '【ご注意】 あなたのレビュー画面に「サロン（施術者）都合によるキャンセル」が発生したことが表示され、評価へと反映されます。',
      EmergencyCanCel: '緊急事態（自然災害・電車/バスの遅延や運休）',
      EmergencyCancelContent: '【ご注意】 Nailieからの事情確認のためのご連絡や必要書類の提出をお願いする場合があります。',
      MessageToCustomer: 'お客様へのメッセージを送信',
      MessageWithEmergencyReason: 'Nailie事務局へ緊急事態の内容を入力してください',
      CancellationCharge: 'キャンセル料',
      CancellationChargeTitle: '「お客様都合によるキャンセル」を行います',
      CancellationChargeContentHead: '【ご注意】お客様理由のキャンセルの場合',
      CancellationChargeContentMiddle: 'お客様に',
      CancellationChargeContentTail: 'のキャンセル料が発生します',
      CancellationChargeContentNofee: '※キャンセル料は発生しません',
      NailistSalesPrice: 'ネイリスト売上',
      Send: '送信',
      StaffWorkingTimeViewModalContentHead: '',
      StaffWorkingTimeViewModalContentTail: 'から今までのスタッフのスケジュールです',
      StaffWorkingTimeFooter: 'この設定は明日から有効します.',
      ViewHere: 'もっと見る',
      OFF: '休む',
      LatestScheduleHead: '以下のスケジュールは',
      LatestScheduleTail: 'から有効します。',
      ClickHereEnglish: '',
      ClickHereJapanese: 'ここにクリックしてください',
      PastScheduleHead: '',
      PastScheduleTail: 'から今までのスタッフのスケジュールを見るために、',
      'Show Booking': '予約を見る',
      SetRecommend: 'おすすめのメニューを選択',
      RemoveRecommend: 'おすすめのメニューを削除',
      Other: 'コロナ対策',
      SurveyCoronaText: 'コロナウイルスの対策状況をお客様に表示できるようになりました。あなたのサロンで行っている対策を選択しましょう。',
      SaveAndPublishSettings: '保存する',
      'Additional price': '金額変更',
      AcceptanceTimeTitle: '受付開始・締切',
      AcceptanceTimeContent: 'ネイリーでは当日予約をするお客様が多数いらっしゃいます。\n 当日の予約を受け付けるだけで予約数が格段にアップします！',
      AcceptanceTimeLabel: '当日予約の締切',
      AcceptanceDateLabel: '受付開始日',
      'Salon sales report': 'サロンの振込報告',
      Period: '振込サイクル',
      'Booking Done': '施術完了数',
      'Customer canceled': 'キャンセル数',
      'Salon canceled': 'サロンキャンセル数',
      'Total treatment fee': '売上',
      'Nailie fee': '予約手数料',
      'Transfer amount': '未払い金額',
      'Staff sales report': 'スタッフの振込報告',
      'Booking data': '予約情報',
      'First period': '月の前半',
      'Second period': '月の後半',
      'Full month': '1ヶ月',
      'View more detail': 'もっと見る',
      Sale: '売上管理',
      Earnings: '振り込み金額',
      'Manage bookings': '予約管理',
      'Search bookings': '予約検索',
      CompleteBookingInstruction: 'クーポン・ポイント利用の場合は割引前の金額を入力してください',
      CompleteBookingSubtotalEmphasize: 'ディスカウント含めない金額に変更します',
      CompleteBookingMemoHelp: '金額変更の理由をお客様に伝えるためメモです',
      CompleteBookingMemoError: 'メモを入力してください',
      CompleteBookingSubtotalErrorHead: '金額は',
      CompleteBookingSubtotalErrorTail: '円以上からです',
      'Complete booking': 'お会計',
      Memo: '金額変更メモ',
      InputMemoPlaceholder: 'メモを入力',
      ChangePrice: '金額を変更する',
      EditPrice: '変更する',
      'Change date time': '予約日時を変更する',
      ChangeBookingDateModalTitle: '予約日時の変更',
      'New booking date': '新しい予約日',
      ChangeDateReasonLabel: 'お客様へのメッセージ',
      Required: '必須',
      Notes: '注意事項',
      ChangeBookingDateModalNotes: 'リクエスト日から60日以内は予約の変更が可能です。サロン（施術者）都合での予約の変更はお控えください。 やむを得ない場合はメッセージにて事前にお客様の了承を得てからの変更をお願いします。',
      'I checked notes': '注意事項を確認しました',
      Reload: '再ロード',
      Reset: 'リセット',
      'Transfer management': '振込予定/履歴',
      Details: ' 詳細 ',
      'Charged amount': '決済済',
      'Total sales': '売上合計',
      'Done amounts': '施術売上',
      'Canceled amounts': 'キャンセル売上',
      Average: '平均単価',
      'Sales amount': '売上金額',
      'Sales management': '売上管理',
      'Payout cycle': '売上日',
      'Booking fee': '予約手数料',
      'Transfer fee': '振込手数料',
      'Transfer date': '振込日',
      Fee: '手数料',
      CountDoneNew: '新規来店数',
      NewType: '新規',
      RepeatType: '再来',
      CountDoneRepeater: '再来来店数',
      BookingCustomerTypeTitle: '新規/再来',
      SubtotalSales: '小計',
      TransferAmountSales: '振込金額',
      'Scheduled transfer': '振込予約',
      'Transfer done': '振込完了',
      'Sales detail': '売上金額の詳細',
      Menu: 'メニュー',
      Case: '件',
      WaitingDoneBookingWarning: '施術が終わったお客様を選択してお会計をしましょう！\n施術終了時間から48時間以内にお会計を完了できなかった場合、その施術はキャンセル扱いとなります。',
      WaitingDoneBookingWarningImportant: '※売上が無くなり、ネイリストの評価が下がりますのでお早めのお会計をお願い致します。',
      Employer: 'スタッフ ',
      Deadline: 'お会計期限まで ',
      SalonIntegrationTitle: '外部連携',
      SalonConnect3rdPartiesScheduleDescription: '外部連携する予約管理システムを選択できます。（同時連携することはできません）',
      SalonBMScheduleLabel: 'Beauty Meritのスケジュールを連携する',
      Allow: '開始',
      Prevent: '終了',
      Connect: '連携する',
      ConnectWithCSTitle: 'coming-soonと連携する',
      Token: 'トークン',
      InputTokenCSPlaceholder: 'coming-soonのトークンを入力',
      InputTokenCSError: '正しい認証トークンを入力してください',
      ConnectCSInputTokenDescription: 'Nailieとcoming-soonを自動連携するには、coming-soonで発行された認証トークンを入力する必要があります。認証トークンについてわからない場合は、coming-soonにお問い合わせください。',
      SalonCommon: 'サロン共通',
      NailistOnly: 'ネイリストのみ',
      EyelistOnly: 'アイリストのみ',

      BMContentHead: 'Beauty Meritとのスケジュールを連携を',
      BMContentTail: 'しますか?',
      BMContentDisconnect1: 'ビューティーメリットとの予約連携を解除します。よろしいですか？',
      BMContentDisconnect2: '※連携を解除すると、受付開始日（●●日後）以降のスケジュールは営業時間設定に基づき「○」となります',
      BMContentConnect: 'Beauty Meritとのスケジュール連携を開始しますか？',
      BMDisConnectAlert: 'Beauty Meritを切断してください!',
      CSDisConnectAlert: 'coming-soonを切断してください!',
      CSContentDisconnect: 'coming-soonとの連携を終了しますか？',
      CSContentConnect: 'coming-soonとのスケジュール連携を開始しますか？',
      CSContentHead: 'coming-soonのスケジュールとのスケジュールを連携を',
      CSContentTail: 'しますか?',
      BeautyMeritTitle: 'BeautyMerit',
      ComingSoonTittle: 'coming-soon',
      ExternalParties: 'サードパーティ',
      'Send the update': '変更を送信する',
      'Confirmation Popup': '予約日時の変更を行います。よろしいですか？',
      AppliedNailistScheduleHead: '',
      AppliedNailistScheduleTail: ' ネイリストに適用されたデータ。',
      ChangeBookingDateSuccess: '予約日時変更が完了しました',
      NavigationAccess: '道案内・アクセス',
      WalkingTime: '徒歩分数',
      NearestStation: '最寄駅',
      NearestStationExtraInfo1: '※最寄駅を設定していなくても半径1.5km圏内で自動検出されるため、検索結果には表示されます',
      NearestStationExtraInfo2: '※最寄駅がない場合は空欄のままにしてください',
      MinutesWalkingTime: '分',
      UpdateStationPopupTitle: 'サロンの最寄駅を。設定できるようになりました！',
      UpdateStationPopupContent: '最寄駅を設定すると。ネイリスト検索でヒットしやすくなります！。今すぐ設定しましょう！',
      UpdatePropertiesPopupTitle: '新しいサロンの特徴を追加しました。選択できるようになりました！',
      UpdatePropertiesPopupContent: '基本設定 > サロンの特徴からで設定・変更が可能です。あなたのサロンに当てはまる項目をクリックして保存で設定を完了しましょう！',
      UpdateStationPopupOkText: 'さっそく設定する',
      UpdateStationPopupCancelText: 'あとで',
      StaffInfo: 'スタッフ情報',
      StaffInforSearchPlaceholder: '電話番号またはメールアドレスで検索',
      ConfirmArriveOnTime: '時間通りに来店されましたか?',
      AdditionalInfor: '補足情報',
      NailieBookedNote: 'Nailie予約',
      DelayedNote: '遅刻',
      CanceledNote: 'キャンセル',
      Times: '回',
      Retry: '読み込む',
      CSConnectStaffTitle: '外部連携先のスタッフと紐付けることでスケジュールが自動連携します。',
      CsConnectStaffWarningHead: 'スタッフ情報を取得する際に少し時間がかかってしまう場合があります。',
      CsConnectStaffWarningTail: '経過が長くかかっている場合は再度読み込むことができます。',
      SalonStaff: 'Nailieのスタッフ',
      ComingSoonStaff: 'coming-soonのスタッフ',
      SelectStaffPlaceholder: 'スタッフを選ぶ',
      DisconnectThirdParty: '接続しない',
      DisconnectWithBMBtn: '連携を解除する',
      DisconnectWithCSBtn: 'coming-soon連携を解除する',
      ConnectedWithCS: 'coming-soonと連携する',
      ProcedureLinkCS: 'coming-soonを連携する手順',
      ConnectCSstep1: '① coming-soonで、ネイリーとの連携開始の操作を行なってください',
      ConnectCSstep: '操作手順:',
      ConnectCSstepLine1: 'coming-soonの「外部連携」⇨Nailieページを表示',
      ConnectCSstepLine2: '「予約情報を反映する」設定になっていることを確認後、実行パスワードを入力し、「認証トークンを発行する」',
      ConnectCSstep2: '② サロンマネージャーの「coming-soon 連携を開始する」をONにしてください',
      ConnectCSstepLine3: 'coming-soon連携を開始する',
      ConnectCSstepLine4: 'coming-soonで発行された認証トークンを入れてください',
      ConnectCSstepLine5: 'coming-soonとネイリーのスタッフの紐付けを行う',
      ConnectCSstepNote: '【注意事項】',
      ConnectCSstepNoteContent: 'スタッフの同期は1名につき15-20分程かかります',
      ConnectedWithBM: 'BeautyMeritと連携する',
      HowToConnectBM: 'BeautyMeritを連携する手順',
      TurnOnSwitchButton: '①　サロンマネージャーの「BeautyMerit連携を開始する」をONにしてください',
      BeautyMeritDescription1: ' ※サロンマネージャーでは〇×の操作ができなくなります。',
      FollowProgressSetting: '②　BeautyMeritで、ネイリーとの連携開始の操作を行ってください',
      BMConnectSteps: '操作手順',
      BMStepLine1: 'BeautyMeritの「オプション」⇒「一元管理設定」ページを表示',
      BMStepLine2: 'ネイリーのアカウント設定で、サロンマネージャーのログインID(メールアドレス)とパスワードを入力する',
      BMStepLine3: 'BeautyMeritとネイリーのスタッフの紐付けを行う',
      BMNoteLine1: 'BeautyMerit側で連携開始の操作が完了後、初めにスケジュールの同期を行うためBeautyMeritの',
      BMNoteLine2: '問い合わせ窓口にお問い合わせください。スケジュールの同期はスタッフ1名につき15-20分程かかります。',
      BMNoteLine3: '',
      BMNoteLine4: '連携中にスタッフを追加した場合、該当スタッフのスケジュールの同期が必要となりますのでBeautyMerit',
      BMNoteLine5: '担当者へ必ずご連絡をお願いいたします。',
      WarningDisconnectOther3rdPartyHead: '現在他の外部システムと連携しています。',
      WarningDisconnectOther3rdPartyTail: '今ある連携を解除後、再接続してください。',
      WarningDisconnectOther3rdPartyTitle: '警告',
      ScheduleWarningWhenConnected3rd: 'これは、通常のスケジュールツールとして使用しないでください。スケジュールは引き続き外部のWebサイトで管理する必要があります。',
      Back: '戻る',
      EmergencyCanCelTitle: '緊急事態キャンセル',
      EmergencyCanCelDescription: '以下の理由で、キャンセル手続きを行います。',
      EmergencyReasonExample: '「緊急事態（自然災害・電車/バスの遅延や運休）」',
      EmergencyAttentionNote: '【ご注意】緊急事態によるキャンセルの場合Nailieからの事情確認のためのご連絡や必要書類の提出をお願いする場合があります。',
      EmergencyConfirmSelect: '緊急事態の詳細を確認しました',
      MessageToCustomerNote: '※こちらの内容はお客様へメッセージとして送信されます',
      MessageToOurCustomerConfirmed: 'お客様へのメッセージ',
      MessageToAdminOperatorConfirmed: '運営事務局へのメッセージ',
      MessageToNaileOperator: '緊急事態キャンセルの理由を教えてください',
      MessageToOperatorNote: '※こちらの内容は運営事務局で確認させていただきます。',
      EmptyEmergencyPolicy: '緊急事態の詳細にチェックを入れ同意してください',
      EmergencyCancelConfirmTitle: '緊急事態（自然災害・電車/バスの遅延や運休）によるキャンセル手続きを行いますよろしいですか？',

      NailistCanCelTitle: 'ネイリストキャンセル',
      NailistCanCelDescription: '「サロン（施術者）都合によるキャンセル」を行います',
      NailistAttentionNote: '【ご注意】サロン（施術者）都合によるキャンセルの場合あなたのレビュー画面に「サロン（施術者）都合によるキャンセル」が発生したことが表示され、評価へと反映されます。',
      ReviewImage: 'レビュー表示イメージ',
      AutomaticPostingTitle: '【ネイリー運営事務局の自動投稿】',
      AutomaticPostingContent: '○月○日○○：○○-の予約が、ネイリストの都合によりキャンセルされました。',
      NailistCancelConfirmContent: '承認済みの予約をキャンセルするとネイリストの評価が下がります。キャンセルしてもよろしいですか？',
      WarningCompleteMapping: 'それぞれのシステム内のスタッフを連携させることで、初期設定が完了します。',
      MappingPopupTitle: '従業員のマッピング',
      ContentDisconnect3rdParty: 'スタッフのカミングスーンとの予約連携を解除します。よろしいですか？',
      ContentDisconnect3rdPartyHead: '※連携を解除すると、対象のスタッフは受付開始日(',
      ContentDisconnect3rdPartyTail: '日後）以降のスケジュールは営業時間設定に基づき「○」となります',
      Unlink: '予約連携を解除します',
      BookingConfirmedDate: '予約送信日',
      SettlementFee: '決済手数料',
      ChangeEmailAddress: 'メール アドレス を 変更 する',
      ChangeEmailInput: '新しいメールアドレス',
      ChangeSalonEmail: 'メールアドレスの変更はこちらから',
      ChangeSalonEmailModalTitle: 'メール アドレス 変更 プロセス',
      ChangeEmailStep1: '新しいアドレスにメールを送信',
      ChangeEmailStep2: 'メールリンクをクリックする',
      ChangeEmailStep3: '更新完了',
      ChangeEmailWarning: '※メールアドレスを変更すると、ログイン時のメールアドレスも自動で新しいものに更新されます',
      ChangedEmailAlertContent1: '認証用のメールを送信しました！',
      ChangedEmailAlertContent2: 'メール内のURLをクリックして認証を完了してください。',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ja',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
